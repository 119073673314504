import React from 'react';
import Eboti1 from '../../../../components/eBotiStates/Eboti1/Eboti1';
import Eboti2 from '../../../../components/eBotiStates/Eboti2/Eboti2';
import Eboti3 from '../../../../components/eBotiStates/Eboti3/Eboti3';
import Eboti4 from '../../../../components/eBotiStates/Eboti4/Eboti4';
import Eboti5 from '../../../../components/eBotiStates/Eboti5/Eboti5';
import Eboti6 from '../../../../components/eBotiStates/Eboti6/Eboti6';
import Eboti7 from '../../../../components/eBotiStates/Eboti7/Eboti7';
import Eboti8 from '../../../../components/eBotiStates/Eboti8/Eboti8';
import Eboti9 from '../../../../components/eBotiStates/Eboti9/Eboti9';
import Eboti10 from '../../../../components/eBotiStates/Eboti10/Eboti10';
import Eboti11 from '../../../../components/eBotiStates/Eboti11/Eboti11';
import Eboti12 from '../../../../components/eBotiStates/Eboti12/Eboti12';
import Eboti13 from '../../../../components/eBotiStates/Eboti13/Eboti13';
import Eboti14 from '../../../../components/eBotiStates/Eboti14/Eboti14';
import Eboti15 from '../../../../components/eBotiStates/Eboti15/Eboti15';
import Eboti16 from '../../../../components/eBotiStates/Eboti16/Eboti16';
import Eboti17 from '../../../../components/eBotiStates/Eboti17/Eboti17';
import Eboti18 from '../../../../components/eBotiStates/Eboti18/Eboti18';

const Eboti = (index) => {
  if (index.index === 0) {
    return <Eboti9 />;
  } if (index.index === 1) {
    return <Eboti1 />;
  } if (index.index === 2) {
    return <Eboti7 />;
  } if (index.index === 3) {
    return <Eboti15 />;
  } if (index.index === 4) {
    return <Eboti14 />;
  } if (index.index === 5) {
    return <Eboti12 />;
  } if (index.index === 6) {
    return <Eboti9 />;
  }
  return '';
};

export default Eboti;
