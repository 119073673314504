import * as React from 'react';
import { motion } from 'framer-motion';

function WaterAnimationSlide6(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="water_slide_6__clip-path">
          <path className="water_slide_6__cls-1" d="M0 0h1440v797H0z" />
        </clipPath>
        <clipPath id="water_slide_6__clip-path-2">
          <rect
            className="water_slide_6__cls-1"
            x={241.36}
            y={357.67}
            width={37}
            height={16.88}
            rx={8.44}
            transform="rotate(-.25 258.182 364.966)"
          />
        </clipPath>
        <clipPath id="water_slide_6__clip-path-3">
          <path
            className="water_slide_6__cls-1"
            d="M267.39 362.58a4.46 4.46 0 111.89 6.06 4.48 4.48 0 01-1.89-6.06"
          />
        </clipPath>
        <style>
          {
            '.water_slide_6__cls-1{fill:none}.water_slide_6__cls-15{mix-blend-mode:multiply}.water_slide_6__cls-6{fill:#f5acea}.water_slide_6__cls-7{fill:#e17a47}.water_slide_6__cls-10{fill:#4a494b}.water_slide_6__cls-15{opacity:.4}.water_slide_6__cls-16{fill:#def2fd}.water_slide_6__cls-17{fill:#6a88ed}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          clipPath="url(#water_slide_6__clip-path)"
          id="water_slide_6__Layer_1"
          data-name="Layer 1"
        >
          <g id="water_slide_6__floats">
            <g transform="translate(-461.15 -271.35)">
              <animateMotion
                dur="20s"
                repeatCount="indefinite"
                rotate="auto"
                path="M497.64,214.72c19.6-28.32,68.83-37.87,107-22.36,38.9,15.84,53,52.4,56.64,63.5,13.51,41.08,8.31,75.21-17.44,103.5-21.11,23.19-54.2,25.12-97.76,23.33-51.43-2.13-91.66,11.47-108.94,43.16-20.27,37.17-6,112.13,39.24,121.93,42.76,9.26,93-40.4,91.53-83.51-2-58.76-76.26-162.15-127.15-81.44-12.64,20-12.7,49.35-31.25,76.95-22.59,33.6-95.43,46.85-130.36-.64-39.23-53.34-23.69-98.78,14-120.63,11.63-6.73,55.93-17.48,92-3.52,40.8,15.78,85.78,14.67,104.15-4.7C515.07,303.1,471,253.18,497.64,214.72Z"
              />
              <g transform="rotate(88.1 461.15 271.35)">
                <rect
                  x={454.86}
                  y={268.41}
                  width={12.65}
                  height={5.87}
                  rx={2.94}
                  transform="rotate(-88.1 461.15 271.35)"
                  fill="#db73d7"
                />
                <motion.g
                  className="wing"
                  animate={{
                    scaleX: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'right',
                    transformBox: 'fill-box',
                  }}
                >
                  <path
                    className="water_slide_6__cls-6"
                    d="M460 274l-6.25-.11a25.36 25.36 0 01-25-25.78l.14-8.5 5.34.08a26.27 26.27 0 0125.85 26.7z"
                  />
                  <path
                    className="water_slide_6__cls-6"
                    d="M439 292.28l.13-3.84a15.13 15.13 0 0115.63-14.62l5.24.18-.13 3.84a15.13 15.13 0 01-15.63 14.62z"
                  />
                </motion.g>
                <motion.g
                  className="wing"
                  animate={{
                    scaleX: [1, 0.5, 1],
                  }}
                  transition={{
                    duration: 1, delay: 0.5, ease: 'easeOut', repeat: Infinity,
                  }}
                  style={{
                    originY: 'bottom',
                    originX: 'left',
                    transformBox: 'fill-box',
                  }}
                >
                  <path
                    className="water_slide_6__cls-6"
                    d="M462.28 274l6.25-.11a25.37 25.37 0 0025-25.78l-.14-8.5-5.35.08a26.29 26.29 0 00-25.85 26.7z"
                  />
                  <path
                    className="water_slide_6__cls-6"
                    d="M483.26 292.28l-.13-3.84a15.14 15.14 0 00-15.63-14.62l-5.19.17.12 3.84a15.14 15.14 0 0015.63 14.62z"
                  />
                </motion.g>
              </g>
            </g>
            <g
              id="water_slide_6__bee-2"
              data-name="bee"
              transform="translate(-258.182 -364.966)"
            >
              <animateMotion
                dur="20s"
                repeatCount="indefinite"
                rotate="auto"
                path="M-10.41,421.14c16.19,22.34,38.8,133.18,136.08,134,59.41.51,113-101.91,207.83-94,74.89,6.25,139.58,126,275.66,59,104.08-51.25-2.8-147.86,110.33-151.72,76.64-2.61,147-43,120.2-92.68C713.26,41,846.26-81.94,966.88-92.25c66.49-5.68,184.12-16,506.17,220.61"
              />
              <path
                className="water_slide_6__cls-7"
                d="M263.05 378.79l1.59.02 1.14-4.36-1.59-.02-1.14 4.36zM260.02 378.76l1.59.02 1.14-4.37-1.59-.02-1.14 4.37zM253.01 378.67l1.6.02 1.14-4.37-1.59-.02-1.15 4.37zM249.99 378.63l1.59.02 1.14-4.36-1.59-.02-1.14 4.36z"
              />
              <rect
                x={241.36}
                y={357.67}
                width={37}
                height={16.88}
                rx={8.44}
                transform="rotate(-.25 258.182 364.966)"
                fill="#f4dc5c"
              />
              <g clipPath="url(#water_slide_6__clip-path-2)">
                <path
                  className="water_slide_6__cls-10"
                  d="M285.29 371.85c-5 5.63-12.61 3.36-14.43-1.38-.31.09-.62.17-.94.24 2 5.41 10.34 7.9 15.88 2-.16-.28-.33-.57-.51-.86z"
                />
              </g>
              <g
                clipPath="url(#water_slide_6__clip-path-3)"
                id="water_slide_6__eye-2"
                data-name="eye"
              >
                <path
                  d="M267.39 362.58a4.46 4.46 0 111.89 6.06 4.48 4.48 0 01-1.89-6.06"
                  fill="#f2f3f3"
                />
                <path
                  className="water_slide_6__cls-10"
                  d="M271.35 364.62a4.45 4.45 0 111.89 6.06 4.49 4.49 0 01-1.89-6.06"
                />
              </g>
              <path
                fill="#ddb459"
                d="M251.96 374.58l6.85-.03 5.43-.02-.07-16.88-4.94.02-7.34.03.07 16.88z"
              />
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  d="M238.18 340.37h1.53a17.4 17.4 0 0117.47 17.32h-1.54a17.38 17.38 0 01-17.46-17.31z"
                  fill="#ed8c75"
                />
              </motion.g>
              <motion.g
                animate={{
                  scaleY: [0.5, 1, 0.5],
                }}
                transition={{ duration: 0.2, ease: 'easeOut', repeat: Infinity }}
                style={{
                  originY: 'bottom',
                  transformBox: 'fill-box',
                }}
              >
                <path
                  className="water_slide_6__cls-7"
                  d="M272.27 338.68v1.54A17.4 17.4 0 01255 357.69v-1.54a17.4 17.4 0 0117.32-17.47z"
                />
              </motion.g>
            </g>
          </g>
          <path
            className="water_slide_6__cls-16"
            d="M987.26 52.57h-11.08c-2.17-8.72-9.73-15.18-18.71-15.18h-5.88c-9 0-16.54 6.46-18.71 15.18h-.09c-12.48 0-22.62 10.66-22.62 23.75a1.63 1.63 0 001.59 1.68h96.52a1.63 1.63 0 001.59-1.68c0-13.09-10.14-23.75-22.61-23.75z"
          />
          <g id="water_slide_6__rain\xE5ber">
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 0, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be"
              className="water_slide_6__cls-17"
              d="M937.57 88.27a.4.4 0 00-.58.17c-.64 1.39-2.56 6.07-1.5 10.14 1.12 4.28 4.12 4.25 6 3.7a4.61 4.61 0 002.95-6.37c-1.44-3.91-5.59-6.83-6.87-7.64z"
            />
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 0.5, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be-2"
              data-name="vandr\xE5be"
              className="water_slide_6__cls-17"
              d="M960.51 88.27a.4.4 0 00-.58.17c-.63 1.39-2.55 6.07-1.49 10.14 1.11 4.28 4.12 4.25 6 3.7a4.61 4.61 0 002.94-6.37c-1.46-3.91-5.58-6.83-6.87-7.64z"
            />
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 1, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be-5"
              data-name="vandr\xE5be"
              className="water_slide_6__cls-17"
              d="M984.68 88.27a.4.4 0 00-.58.17c-.64 1.39-2.56 6.07-1.5 10.14 1.12 4.28 4.12 4.25 6 3.7a4.62 4.62 0 003-6.37c-1.51-3.91-5.6-6.83-6.92-7.64z"
            />
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 1, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be"
              className="water_slide_6__cls-17"
              d="M937.57 88.27a.4.4 0 00-.58.17c-.64 1.39-2.56 6.07-1.5 10.14 1.12 4.28 4.12 4.25 6 3.7a4.61 4.61 0 002.95-6.37c-1.44-3.91-5.59-6.83-6.87-7.64z"
            />
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 1.5, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be-2"
              data-name="vandr\xE5be"
              className="water_slide_6__cls-17"
              d="M960.51 88.27a.4.4 0 00-.58.17c-.63 1.39-2.55 6.07-1.49 10.14 1.11 4.28 4.12 4.25 6 3.7a4.61 4.61 0 002.94-6.37c-1.46-3.91-5.58-6.83-6.87-7.64z"
            />
            <motion.path
              animate={{
                x: ['0px', '50px'],
                y: ['0px', '60px'],
                opacity: [0, 1, 0],
              }}
              transition={{ duration: 2, delay: 2, repeat: Infinity }}
              style={{
                transformBox: 'fill-box',
              }}
              id="water_slide_6__rain_drop\xE5be-5"
              data-name="vandr\xE5be"
              className="water_slide_6__cls-17"
              d="M984.68 88.27a.4.4 0 00-.58.17c-.64 1.39-2.56 6.07-1.5 10.14 1.12 4.28 4.12 4.25 6 3.7a4.62 4.62 0 003-6.37c-1.51-3.91-5.6-6.83-6.92-7.64z"
            />
          </g>
          <g>
            <path
              className="water_slide_6__cls-16"
              d="M173.71 57.78h-18c-3.48-14.14-15.71-24.61-30.3-24.61h-9.54c-14.56 0-26.83 10.47-30.34 24.61h-.15c-20.23 0-36.68 17.28-36.68 38.52a2.66 2.66 0 002.58 2.7H207.8a2.66 2.66 0 002.59-2.72c0-21.22-16.46-38.5-36.68-38.5z"
            />
            <g id="water_slide_6__vanddr\xE5ber-2" data-name="vanddr\xE5ber">
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 0, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-6"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M93.13 115.67a.65.65 0 00-.94.28c-1 2.25-4.14 9.84-2.42 16.45 1.8 6.93 6.68 6.88 9.66 6s6.95-4.27 4.78-10.32c-2.3-6.43-8.99-11.08-11.08-12.41z"
              />
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 0.5, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-7"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M130.34 115.67a.65.65 0 00-.94.28c-1 2.25-4.14 9.84-2.42 16.45 1.8 6.93 6.68 6.88 9.66 6s7-4.27 4.78-10.32c-2.31-6.43-9-11.08-11.08-12.41z"
              />
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 1, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-10"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M169.53 115.67a.65.65 0 00-.94.28c-1 2.25-4.15 9.84-2.43 16.45 1.81 6.93 6.68 6.88 9.67 6s6.94-4.27 4.77-10.32c-2.3-6.43-8.99-11.08-11.07-12.41z"
              />
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 1, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-6"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M93.13 115.67a.65.65 0 00-.94.28c-1 2.25-4.14 9.84-2.42 16.45 1.8 6.93 6.68 6.88 9.66 6s6.95-4.27 4.78-10.32c-2.3-6.43-8.99-11.08-11.08-12.41z"
              />
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 1.5, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-7"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M130.34 115.67a.65.65 0 00-.94.28c-1 2.25-4.14 9.84-2.42 16.45 1.8 6.93 6.68 6.88 9.66 6s7-4.27 4.78-10.32c-2.31-6.43-9-11.08-11.08-12.41z"
              />
              <motion.path
                animate={{
                  x: ['0px', '50px'],
                  y: ['0px', '60px'],
                  opacity: [0, 1, 0],
                }}
                transition={{ duration: 2, delay: 2, repeat: Infinity }}
                style={{
                  transformBox: 'fill-box',
                }}
                id="water_slide_6__rain_drop\xE5be-10"
                data-name="rain_drop\xE5be"
                className="water_slide_6__cls-17"
                d="M169.53 115.67a.65.65 0 00-.94.28c-1 2.25-4.15 9.84-2.43 16.45 1.81 6.93 6.68 6.88 9.67 6s6.94-4.27 4.77-10.32c-2.3-6.43-8.99-11.08-11.07-12.41z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterAnimationSlide6;
