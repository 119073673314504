import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 300"
      {...props}
      style={{
        height: 'auto',
        width: 200,
      }}
    >
      <defs>
        <filter id="shadow-eboti-18" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M117.91 247.5v14.08c0 11.13-7.91 20.17-17.72 20.18-9.8 0-17.76-9-17.78-20.13v-14.08c0-9.72 6.92-17.61 15.48-17.62h4.49c8.62-.02 15.52 7.85 15.53 17.57z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            d="M158.86 211.22l-.89-1a80.45 80.45 0 00-26.52-20.63 71.29 71.29 0 00-60.79-.59 80.54 80.54 0 00-26.91 20.16l-.91 1a2.25 2.25 0 000 2.85l.89 1a80.49 80.49 0 0026.52 20.64 71.38 71.38 0 0060.79.53 80.57 80.57 0 0026.91-20.17l.91-1a2.2 2.2 0 00.53-1.42 2.24 2.24 0 00-.53-1.37z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-4{fill:#199b69}.prefix__cls-7{fill:#006838}'
          }
        </style>
      </defs>
      <motion.g
        animate={{
          y: [0, 20, -140, 0],
          rotate: [0, 0, 360],
        }}
        transition={{
          duration: 2, repeat: Infinity, repeatDelay: 3,
        }}
        style={{
          transformBox: 'fill-box',
          originX: 'center',
          originY: 'center',
        }}
        filter="url(#shadow-eboti-18)"
      >

        <motion.g
          className="body"
          animate={{
            scaleY: [1, 0.8, 1],
          }}
          transition={{
            duration: 2, repeat: Infinity, repeatDelay: 3,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'center',
            originY: 'top',
          }}
        >
          <path
            className="prefix__cls-1"
            d="M117.91 247.5v14.08c0 11.13-7.91 20.17-17.72 20.18-9.8 0-17.76-9-17.78-20.13v-14.08c0-9.72 6.92-17.61 15.48-17.62h4.49c8.62-.02 15.52 7.85 15.53 17.57z"
          />
          <g clipPath="url(#prefix__clip-path)">
            <path
              className="prefix__cls-4"
              d="M117.61 242.48l1.3 10.94a75.49 75.49 0 01-38 4.34L79.58 247c-1.24-10.42 5.19-19.74 14.37-20.84l4.8-.57c9.18-1.08 17.62 6.48 18.86 16.89z"
            />
          </g>
        </motion.g>
        <path
          className="prefix__cls-4"
          d="M120.41 160.38A21.41 21.41 0 0199 181.79a21.41 21.41 0 0121.41-21.41z"
          transform="rotate(9.39 109.726 171.064)"
        />
        <path
          className="prefix__cls-1"
          d="M71.34 144.56a33.57 33.57 0 0133.57 33.57 33.57 33.57 0 01-33.57-33.57z"
          transform="rotate(-170.61 88.128 161.352)"
        />
        <path
          id="prefix__hrad"
          className="prefix__cls-1"
          d="M170 209l-7-7.21a88.64 88.64 0 00-125.67-1.16l-7.18 7.07a5.81 5.81 0 00-.07 8.17l7 7.2a88.12 88.12 0 0062.6 26.71 89.84 89.84 0 0017.32-1.56 88.09 88.09 0 0045.73-24l7.18-7.07A5.79 5.79 0 00170 209z"
        />
        <g clipPath="url(#prefix__clip-path-2)">
          <path
            className="prefix__cls-4"
            d="M158.86 211.22l-.89-1a80.45 80.45 0 00-26.52-20.63 71.29 71.29 0 00-60.79-.59 80.54 80.54 0 00-26.91 20.16l-.91 1a2.25 2.25 0 000 2.85l.89 1a80.49 80.49 0 0026.52 20.64 71.38 71.38 0 0060.79.53 80.57 80.57 0 0026.91-20.17l.91-1a2.2 2.2 0 00.53-1.42 2.24 2.24 0 00-.53-1.37z"
          />
        </g>
        <path
          d="M157.62 212.69l-.87-1a78.59 78.59 0 00-26-19.89 70.81 70.81 0 00-59.51-.55 78.67 78.67 0 00-26.34 19.41l-.89 1a2.13 2.13 0 000 2.74l.87 1a78.59 78.59 0 0026 19.89 70.86 70.86 0 0059.51.56 78.83 78.83 0 0026.34-19.42l.89-1a2.11 2.11 0 00.51-1.37 2 2 0 00-.51-1.37z"
          fill="#beeda2"
        />
        <path
          className="prefix__cls-7"
          d="M109 221.52a1.3 1.3 0 00-1.26 1.32s.08 4-2.24 6.38a6.35 6.35 0 01-4.74 1.8 6.43 6.43 0 01-4.76-1.75c-2.35-2.35-2.38-6.32-2.38-6.39a1.29 1.29 0 00-2.58 0c0 .21 0 5.08 3.12 8.21a8.91 8.91 0 006.62 2.52 10.7 10.7 0 002.16-.21 8.28 8.28 0 004.43-2.37c3.06-3.16 3-8 3-8.25a1.29 1.29 0 00-1.37-1.26z"
        />
        <g>
          <path
            className="prefix__cls-7"
            d="M138.4 214.12l-6.65-11.29a1.28 1.28 0 00-2.13-.2l-8.62 10.5a1.29 1.29 0 002 1.64l7.46-9.08 5.73 9.74a1.28 1.28 0 001.37.61 1.49 1.49 0 00.4-.15 1.3 1.3 0 00.44-1.77zM73.82 202.79a1.3 1.3 0 00-1.42-.6 1.4 1.4 0 00-.72.47l-8.61 10.47a1.3 1.3 0 00.18 1.82 1.29 1.29 0 001.81-.18l7.47-9.08 5.73 9.74a1.27 1.27 0 001.36.61 1.38 1.38 0 00.4-.15 1.29 1.29 0 00.46-1.77z"
          />
        </g>
        <motion.g
          filter="url(#shadow-eboti-18)"
          id="prefix__hand-2"
          data-name="hand"
          animate={{
            rotate: [0, 0, -40, 0],
            y: [0, 0, -55, 0],
            x: [0, -20, 0, 0],
          }}
          transition={{
            duration: 2, repeat: Infinity, repeatDelay: 3,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'center',
            originY: 'center',
          }}
        >
          <path
            className="prefix__cls-1"
            d="M166.42 244.42a24.12 24.12 0 01-24.12 24.12 24.12 24.12 0 0124.12-24.12z"
            transform="rotate(48.08 154.363 256.513)"
          />
          <path
            className="prefix__cls-1"
            d="M155.62 240.25a16.93 16.93 0 01-16.93 16.93 16.93 16.93 0 0116.93-16.93z"
            transform="rotate(10.12 147.18 248.82)"
          />
        </motion.g>
        <motion.g
          filter="url(#shadow-eboti-18)"
          id="prefix__hand-2"
          data-name="hand"
          animate={{
            rotate: [0, 0, 40, 0],
            y: [0, 0, -55, 0],
            x: [0, 20, 0, 0],
          }}
          transition={{
            duration: 2, repeat: Infinity, repeatDelay: 3,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'center',
            originY: 'center',
          }}
        >
          <path
            className="prefix__cls-1"
            d="M32.35 243.94a24.12 24.12 0 0124.12 24.12 24.12 24.12 0 01-24.12-24.12z"
            transform="rotate(156.17 44.402 256.002)"
          />
          <path
            className="prefix__cls-1"
            d="M45.71 243.42a16.93 16.93 0 0116.93 16.93 16.93 16.93 0 01-16.93-16.93z"
            transform="rotate(-165.86 54.182 251.883)"
          />
        </motion.g>
      </motion.g>
    </svg>
  );
}

export default SvgComponent;
