import * as React from 'react';
import { motion } from 'framer-motion';

function WaterNoAnimationSlide7(props) {
  return (
    <svg
      id="water_slide_7__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="water_slide_7__clip-path">
          <path
            id="water_slide_7__eyes"
            className="water_slide_7__cls-1"
            d="M701 326.5a3.49 3.49 0 01-3.47 3 2.72 2.72 0 01-2.57-3.2 3.5 3.5 0 013.45-3 2.7 2.7 0 012.59 3.2z"
          />
        </clipPath>
        <clipPath id="water_slide_7__clip-path-2">
          <path
            id="water_slide_7__eyes-2"
            data-name="eyes"
            className="water_slide_7__cls-1"
            d="M802.75 364.85a3.5 3.5 0 01-.5-4.56 2.71 2.71 0 014.08-.32 3.51 3.51 0 01.53 4.55 2.68 2.68 0 01-4.11.33z"
          />
        </clipPath>
        <clipPath id="water_slide_7__clip-path-3">
          <ellipse
            className="water_slide_7__cls-2"
            cx={670}
            cy={476.34}
            rx={9.31}
            ry={9.34}
          />
        </clipPath>
        <clipPath id="water_slide_7__clip-path-4">
          <path
            className="water_slide_7__cls-3"
            d="M674.13 473.7a4.7 4.7 0 10-2.42 6.26 4.74 4.74 0 002.42-6.26"
          />
        </clipPath>
        <clipPath id="water_slide_7__clip-path-5">
          <path
            className="water_slide_7__cls-3"
            d="M908.8 450.76l18.89-5a8 8 0 005.71-9.8l-.06-.2a8 8 0 00-9.77-5.72l-18.89 5a8 8 0 00-5.71 9.8v.2a8 8 0 009.83 5.72z"
          />
        </clipPath>
        <clipPath id="water_slide_7__clip-path-6">
          <path
            className="water_slide_7__cls-3"
            d="M922.35 435.31a4.28 4.28 0 113.21 5.19 4.3 4.3 0 01-3.21-5.19"
          />
        </clipPath>
        <style>
          {
            '.water_slide_7__cls-1{fill:#fff}.water_slide_7__cls-2{fill:#f9a54d}.water_slide_7__cls-15,.water_slide_7__cls-3{fill:none}.water_slide_7__cls-4{fill:#def2fd}.water_slide_7__cls-5{fill:#6a88ed}.water_slide_7__cls-6{fill:#e17a47}.water_slide_7__cls-8{fill:#ffe67a}.water_slide_7__cls-15{stroke-linecap:round;stroke-linejoin:round}.water_slide_7__cls-10{fill:#f4dc5c}.water_slide_7__cls-11{fill:#fbef7b}.water_slide_7__cls-13{fill:#ddb459}.water_slide_7__cls-15{stroke:#e17a47;stroke-width:1.89px}.water_slide_7__cls-20{fill:#f2f3f3}.prefix__cls-21{fill:#4a494b}'
          }
        </style>
      </defs>
      {/* --------------- cloud 1 ---------------------- */}

      <g
        id="water_slide_7__rain_cloud_1"
      >
        <path
          className="water_slide_7__cls-4"
          d="M645 206.74h-10.2c-2-8-8.94-14-17.18-14h-5.4c-8.25 0-15.2 6-17.19 14h-.09c-11.45 0-20.77 9.81-20.77 21.87a1.5 1.5 0 001.46 1.54h88.66a1.51 1.51 0 001.47-1.54c0-12.06-9.32-21.87-20.76-21.87z"
        />
        <g id="water_slide_7__rain_drops">
          <path
            id="water_slide_7__rain_drop"
            className="water_slide_7__cls-5"
            d="M599.34 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.65-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-2"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M620.42 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.3-3.65-5.1-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-3"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M642.61 239.61a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.31-3.65-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop"
            className="water_slide_7__cls-5"
            d="M599.34 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.65-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-2"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M620.42 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.3-3.65-5.1-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-3"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M642.61 239.61a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.31-3.65-5.1-6.29-6.28-7.04z"
          />
        </g>
      </g>
      <motion.g
        id="water_slide_7__rain_cloud_1"
        animate={{
          x: [0, 1500],
        }}
        transition={{
          duration: 40, repeat: Infinity, ease: 'linear',
        }}
      >
        <path
          className="water_slide_7__cls-4"
          d="M645 206.74h-10.2c-2-8-8.94-14-17.18-14h-5.4c-8.25 0-15.2 6-17.19 14h-.09c-11.45 0-20.77 9.81-20.77 21.87a1.5 1.5 0 001.46 1.54h88.66a1.51 1.51 0 001.47-1.54c0-12.06-9.32-21.87-20.76-21.87z"
        />
        <g id="water_slide_7__rain_drops">
          <path
            id="water_slide_7__rain_drop"
            className="water_slide_7__cls-5"
            d="M599.34 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.65-5.09-6.29-6.27-7.04z"
          />
          <path
            transition={{ duration: 3, delay: 0.5, repeat: Infinity }}
            id="water_slide_7__rain_drop-2"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M620.42 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.3-3.65-5.1-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-3"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M642.61 239.61a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.31-3.65-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop"
            className="water_slide_7__cls-5"
            d="M599.34 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.65-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-2"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M620.42 239.61a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.3-3.65-5.1-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-3"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M642.61 239.61a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.25 4.25 0 002.71-5.86c-1.31-3.65-5.1-6.29-6.28-7.04z"
          />
        </g>
      </motion.g>

      {/* --------------- cloud 2 ---------------------- */}

      <g
        id="water_slide_7__rain_cloud"
        data-name="rain cloud"
      >
        <path
          id="water_slide_7__cloud"
          className="water_slide_7__cls-4"
          d="M309 172.5h-12.32c-2.41-9.71-10.81-16.9-20.78-16.9h-6.54c-10 0-18.37 7.19-20.78 16.9h-.1c-13.86 0-25.13 11.87-25.13 26.45a1.82 1.82 0 001.77 1.87h107.22a1.82 1.82 0 001.77-1.87c0-14.58-11.27-26.45-25.11-26.45z"
        />
        <g id="water_slide_7__rain_drops-2" data-name="rain_drops">
          <path
            id="water_slide_7__rain_drop-4"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M253.79 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.75-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-5"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M279.28 212.25a.45.45 0 00-.65.19c-.7 1.55-2.83 6.76-1.66 11.3 1.24 4.76 4.58 4.73 6.62 4.11s4.76-2.93 3.28-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__vandr\xE5be"
            className="water_slide_7__cls-5"
            d="M306.12 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.76-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-4"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M253.79 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.75-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-5"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M279.28 212.25a.45.45 0 00-.65.19c-.7 1.55-2.83 6.76-1.66 11.3 1.24 4.76 4.58 4.73 6.62 4.11s4.76-2.93 3.28-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__vandr\xE5be"
            className="water_slide_7__cls-5"
            d="M306.12 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.76-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
        </g>
      </g>
      <motion.g
        animate={{
          x: [0, 1500],
        }}
        transition={{
          duration: 40, repeat: Infinity, ease: 'linear',
        }}
        id="water_slide_7__rain_cloud"
        data-name="rain cloud"
      >
        <path
          id="water_slide_7__cloud"
          className="water_slide_7__cls-4"
          d="M309 172.5h-12.32c-2.41-9.71-10.81-16.9-20.78-16.9h-6.54c-10 0-18.37 7.19-20.78 16.9h-.1c-13.86 0-25.13 11.87-25.13 26.45a1.82 1.82 0 001.77 1.87h107.22a1.82 1.82 0 001.77-1.87c0-14.58-11.27-26.45-25.11-26.45z"
        />
        <g id="water_slide_7__rain_drops-2" data-name="rain_drops">
          <path
            id="water_slide_7__rain_drop-4"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M253.79 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.75-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-5"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M279.28 212.25a.45.45 0 00-.65.19c-.7 1.55-2.83 6.76-1.66 11.3 1.24 4.76 4.58 4.73 6.62 4.11s4.76-2.93 3.28-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__vandr\xE5be"
            className="water_slide_7__cls-5"
            d="M306.12 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.76-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-4"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M253.79 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.75-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__rain_drop-5"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M279.28 212.25a.45.45 0 00-.65.19c-.7 1.55-2.83 6.76-1.66 11.3 1.24 4.76 4.58 4.73 6.62 4.11s4.76-2.93 3.28-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
          <path
            id="water_slide_7__vandr\xE5be"
            className="water_slide_7__cls-5"
            d="M306.12 212.25a.43.43 0 00-.64.19c-.71 1.55-2.84 6.76-1.66 11.3 1.23 4.76 4.57 4.73 6.62 4.11s4.76-2.93 3.27-7.08c-1.58-4.41-6.16-7.61-7.59-8.52z"
          />
        </g>
      </motion.g>
      {/* --------------- cloud 3 ---------------------- */}
      <g
        id="water_slide_7__rain_cloud_3"
      >
        <path
          id="water_slide_7__cloud-2"
          data-name="cloud"
          className="water_slide_7__cls-4"
          d="M948.72 241.46h-10.18c-2-8-8.94-14-17.19-14H916c-8.24 0-15.19 5.94-17.18 14h-.09c-11.46 0-20.78 9.81-20.78 21.87a1.51 1.51 0 001.47 1.55H968a1.5 1.5 0 001.46-1.55c.03-12.06-9.29-21.87-20.74-21.87z"
        />
        <g id="water_slide_7__rain_drops-3" data-name="rain_drops">
          <path
            id="water_slide_7__rain_drop-6"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M903.07 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.24 4.24 0 002.71-5.86c-1.35-3.64-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-7"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M924.15 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.38 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-8"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M946.35 274.33a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-6"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M903.07 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.24 4.24 0 002.71-5.86c-1.35-3.64-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-7"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M924.15 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.38 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-8"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M946.35 274.33a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
        </g>
      </g>
      <g
        id="water_slide_7__rain_cloud_3"
      >
        <path
          id="water_slide_7__cloud-2"
          data-name="cloud"
          className="water_slide_7__cls-4"
          d="M948.72 241.46h-10.18c-2-8-8.94-14-17.19-14H916c-8.24 0-15.19 5.94-17.18 14h-.09c-11.46 0-20.78 9.81-20.78 21.87a1.51 1.51 0 001.47 1.55H968a1.5 1.5 0 001.46-1.55c.03-12.06-9.29-21.87-20.74-21.87z"
        />
        <g id="water_slide_7__rain_drops-3" data-name="rain_drops">
          <path
            id="water_slide_7__rain_drop-6"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M903.07 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.24 4.24 0 002.71-5.86c-1.35-3.64-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-7"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M924.15 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.38 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-8"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M946.35 274.33a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-6"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M903.07 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.37 9.34 1 3.94 3.78 3.91 5.47 3.4a4.24 4.24 0 002.71-5.86c-1.35-3.64-5.1-6.29-6.28-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-7"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M924.15 274.33a.36.36 0 00-.53.16c-.59 1.28-2.35 5.59-1.38 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
          <path
            id="water_slide_7__rain_drop-8"
            data-name="rain_drop"
            className="water_slide_7__cls-5"
            d="M946.35 274.33a.37.37 0 00-.54.16c-.58 1.28-2.34 5.59-1.37 9.34 1 3.94 3.79 3.91 5.48 3.4a4.24 4.24 0 002.7-5.86c-1.3-3.64-5.09-6.29-6.27-7.04z"
          />
        </g>
      </g>
      {/* --------------- cloud 3 end ------------------ */}

      <g id="water_slide_7__float">
        <g id="water_slide_7__flying_bird singing_bird" data-name="flying bird">
          <path
            className="water_slide_7__cls-6"
            d="M418.56 300.91a1.18 1.18 0 00-1.14-.39c-10 2.11-22.06 13.3-26.83 24.94l-.56 1.36a3 3 0 00.18 3.12 2.15 2.15 0 001.71.76 3.36 3.36 0 00.63-.06l12.94-2.73a6.79 6.79 0 004.29-3.92l9-21.91a1.13 1.13 0 00-.22-1.17z"
          />
          <path
            className="water_slide_7__cls-2"
            d="M398.5 294.38a12.92 12.92 0 00-9.17 12.81l1 17.22 19.21-4.87-.78-17.37c-.23-5.7-4.84-9.17-10.26-7.79z"
          />
          <path
            className="water_slide_7__cls-2"
            d="M384.3 340.83l8.94-1.13c9-1.13 16.83-8.75 16.45-17.23l-.2-4.43c0-.55-.6-.86-1.29-.69l-41 10.42a.78.78 0 00-.55.89c1.35 7.93 8.76 13.34 17.65 12.17z"
          />
          <path
            className="water_slide_7__cls-6"
            d="M394.71 329l-14.59 3.07c-1.46.31-3.57-.74-4.72-2.34l-1-1.45c-8.61-12-6.71-23.67 4.24-26l16.62 23.25c1.16 1.62.9 3.17-.55 3.47z"
          />
          <path
            d="M406.65 308.87l9.06 3.11a1.88 1.88 0 002.34-1.65 2.48 2.48 0 00-1.35-2.91l-9.06-3.11a1.9 1.9 0 00-2.35 1.65 2.5 2.5 0 001.36 2.91z"
            fill="#f1bc69"
          />
          <path
            className="water_slide_7__cls-8"
            d="M408.18 308.47l7.82-6.31a2.7 2.7 0 00.53-3.27 1.71 1.71 0 00-2.65-.7l-7.82 6.31a2.7 2.7 0 00-.53 3.27 1.71 1.71 0 002.65.7z"
          />
          <path
            d="M400.59 303.9a3.68 3.68 0 00-4.36-2.6c-3.48.65-2.81 4.14-2.81 4.14"
            strokeWidth={1.41}
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#4a494b"
            fill="none"
          />
          <g
            id="water_slide_7__heart"
          >
            <path
              className="water_slide_7__cls-8"
              d="M438.27 302l-3 5.92a1.45 1.45 0 01-2 .63l-8.67-5.11a4 4 0 01-1.43-5.29l.26-.51a3.52 3.52 0 014.94-1.53z"
            />
            <path
              className="water_slide_7__cls-8"
              d="M430.47 306.92l-2.78-1.64 5.59-10.86a3.53 3.53 0 015-1.54l.43.26a4 4 0 011.44 5.31l-3.38 6.55a4.49 4.49 0 01-6.3 1.92z"
            />
          </g>
          <g
            className="music_note"
          >
            <path
              className="water_slide_7__cls-8"
              d="M419.67 320.65l10.33-3.14a.65.65 0 01.81.47l2.58 9.12a2.2 2.2 0 011.53-.62 2.48 2.48 0 010 4.94 2.35 2.35 0 01-2.28-2.12l-2.91-10.24-9.07 2.76 2 8.13a2.25 2.25 0 011.48-.57 2.48 2.48 0 010 4.95 2.36 2.36 0 01-2.27-2l-2.61-10.8a.71.71 0 01.41-.88z"
            />
          </g>
        </g>
        <g
          id="water_slide_7__bird"
        >
          <path
            className="water_slide_7__cls-6"
            d="M682.11 320.83a25.74 25.74 0 0114-13.73.49.49 0 01.47.06.49.49 0 01.23.41l1 27.53a.51.51 0 01-.16.4.5.5 0 01-.39.14l-16.48-1h-.14a.54.54 0 01-.35-.43l-.11-.95a23.75 23.75 0 011.93-12.43z"
          />
          <path
            className="water_slide_7__cls-10"
            d="M688 325.2l13.85-3.41a.52.52 0 01.42.07.55.55 0 01.22.37l1.2 9.72c0 .18 0 .36.05.54a10.3 10.3 0 01-8.06 10.5l-22.07 4.6a.54.54 0 01-.28 0 .47.47 0 01-.12-.07.51.51 0 01-.22-.35 18.5 18.5 0 01-.08-4.82A20.33 20.33 0 01688 325.2z"
          />
          <path
            className="water_slide_7__cls-11"
            d="M687.86 335.43l15.2-3.42a.54.54 0 01.43.09.53.53 0 01.21.39 10.3 10.3 0 01-8.06 10.5l-14.2 3a.67.67 0 01-.28 0l-.12-.07a.51.51 0 01-.22-.35 9.24 9.24 0 017.04-10.14z"
          />
          <path
            className="water_slide_7__cls-10"
            d="M684.83 307.05a.51.51 0 01.46-.09.52.52 0 01.35.31l9.42 24.55a.48.48 0 010 .42.55.55 0 01-.33.27l-16.25 4.37a.47.47 0 01-.31 0 .5.5 0 01-.3-.27l-.4-.83a21.9 21.9 0 01-1.82-11.92 25.41 25.41 0 019.18-16.81z"
          />
          <g id="water_slide_7__general_eye_shape" data-name="general eye shape">
            <g id="water_slide_7__EYE">
              <path
                id="water_slide_7__eyes-3"
                data-name="eyes"
                className="water_slide_7__cls-1"
                d="M701 326.5a3.49 3.49 0 01-3.47 3 2.72 2.72 0 01-2.57-3.2 3.5 3.5 0 013.45-3 2.7 2.7 0 012.59 3.2z"
              />
              <g clipPath="url(#water_slide_7__clip-path)">
                <path
                  id="water_slide_7__eyes-4"
                  data-name="eyes"
                  d="M703 325.68a3.52 3.52 0 01-3.51 3 2.71 2.71 0 01-2.57-3.21 3.53 3.53 0 013.48-3 2.71 2.71 0 012.6 3.21z"
                />
              </g>
            </g>
          </g>
          <path
            id="water_slide_7__n\xE6b"
            className="water_slide_7__cls-13"
            d="M707.84 324.8l-4.94 1.2-.29-2.33 4.95-1.2a1 1 0 011.23.82v.19a1.29 1.29 0 01-.95 1.32z"
          />
        </g>
        <g
          id="water_slide_7__bird-2"
          data-name="bird"
        >
          <path
            className="water_slide_7__cls-6"
            d="M788.64 356.66a25.71 25.71 0 0114-13.73.52.52 0 01.47.05.54.54 0 01.24.42l1 27.53a.51.51 0 01-.15.39.54.54 0 01-.4.15l-16.48-1a.32.32 0 01-.14 0 .52.52 0 01-.35-.42l-.11-.95a23.73 23.73 0 011.92-12.44z"
          />
          <path
            className="water_slide_7__cls-10"
            d="M794.49 361l13.85-3.41a.56.56 0 01.42.08.53.53 0 01.22.37l1.2 9.71a5.16 5.16 0 01.05.54 10.28 10.28 0 01-8.06 10.5l-22.07 4.6a.54.54 0 01-.28 0 .34.34 0 01-.12-.06.51.51 0 01-.22-.35 18.51 18.51 0 01-.07-4.82A20.32 20.32 0 01794.49 361z"
          />
          <path
            className="water_slide_7__cls-11"
            d="M794.4 371.26l15.2-3.42a.49.49 0 01.42.09.49.49 0 01.21.38 10.28 10.28 0 01-8.06 10.5l-14.19 3a.53.53 0 01-.28 0 .52.52 0 01-.13-.06.53.53 0 01-.22-.36 9.23 9.23 0 017.05-10.13z"
          />
          <path
            className="water_slide_7__cls-10"
            d="M791.36 342.87a.54.54 0 01.46-.09.53.53 0 01.35.32l9.43 24.54a.54.54 0 010 .43.46.46 0 01-.33.26L785 372.71a.54.54 0 01-.31 0 .6.6 0 01-.3-.27l-.39-.83a21.83 21.83 0 01-1.83-11.92 25.41 25.41 0 019.19-16.82z"
          />
          <g id="water_slide_7__general_eye_shape-2" data-name="general eye shape">
            <g id="water_slide_7__EYE-2" data-name="EYE">
              <path
                id="water_slide_7__eyes-5"
                data-name="eyes"
                className="water_slide_7__cls-1"
                d="M802.75 364.85a3.5 3.5 0 01-.5-4.56 2.71 2.71 0 014.08-.32 3.51 3.51 0 01.53 4.55 2.68 2.68 0 01-4.11.33z"
              />
              <g clipPath="url(#water_slide_7__clip-path-2)">
                <path
                  id="water_slide_7__eyes-6"
                  data-name="eyes"
                  d="M802.33 366.91a3.54 3.54 0 01-.49-4.59 2.7 2.7 0 014.09-.33 3.55 3.55 0 01.54 4.59 2.72 2.72 0 01-4.14.33z"
                />
              </g>
            </g>
          </g>
          <path
            id="water_slide_7__n\xE6b-2"
            data-name="n\xE6b"
            className="water_slide_7__cls-13"
            d="M814.37 360.62l-4.94 1.18-.28-2.33 4.94-1.2a1 1 0 011.24.81v.2a1.28 1.28 0 01-.96 1.34z"
          />
        </g>
        <g
          id="water_slide_7__dragonfly"
        >
          <path
            className="water_slide_7__cls-15"
            d="M679.64 485.82l2.33 8.9M682.98 486.65l2.33 8.91"
          />
          <path
            d="M670.17 436.47l-.13.06a5.32 5.32 0 00-2.76 7l14.1 32.69a5.3 5.3 0 007 2.76l.13-.05a5.32 5.32 0 002.76-7l-14.1-32.69a5.32 5.32 0 00-7-2.77z"
            fill="#f4d068"
          />
          <path
            className="water_slide_7__cls-2"
            d="M687.8 489.07l-11.9-3a5.47 5.47 0 01-4-6.63v-.14a5.46 5.46 0 016.62-4l11.89 3a5.45 5.45 0 014 6.63v.14a5.45 5.45 0 01-6.61 4z"
          />
          <path
            className="water_slide_7__cls-2"
            d="M688.75 477.88l38.35 9.61a3.24 3.24 0 012.35 3.93v.08a3.24 3.24 0 01-3.92 2.36l-38.35-9.61a3.23 3.23 0 01-2.35-3.93v-.08a3.23 3.23 0 013.92-2.36z"
          />

          <path
            className="water_slide_7__cls-11"
            d="M720.19 448.75l-.09-.1a5.3 5.3 0 00-7.5-.31l-26.11 24.14a5.33 5.33 0 00-.31 7.52l.1.1a5.3 5.3 0 007.5.31l26.11-24.14a5.32 5.32 0 00.3-7.52z"
          />
          <ellipse
            className="water_slide_7__cls-2"
            cx={670}
            cy={476.34}
            rx={9.31}
            ry={9.34}
          />
          <g clipPath="url(#water_slide_7__clip-path-3)">
            <ellipse
              className="water_slide_7__cls-2"
              cx={670}
              cy={476.34}
              rx={9.31}
              ry={9.34}
            />
            <path
              d="M659.15 481.33a4.4 4.4 0 003.72 1.53 4.66 4.66 0 003.77-1.59"
              strokeWidth={0.87}
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#4a494b"
              fill="none"
            />
          </g>
          <g clipPath="url(#water_slide_7__clip-path-4)">
            <path
              className="water_slide_7__cls-20"
              d="M674.13 473.7a4.7 4.7 0 10-2.42 6.26 4.74 4.74 0 002.42-6.26"
            />
            <path
              className="water_slide_7__cls-21"
              d="M669.81 475.57a4.7 4.7 0 10-2.42 6.26 4.74 4.74 0 002.42-6.26"
            />
          </g>
        </g>
        <g
          id="water_slide_7__bee"
        >
          <path
            className="water_slide_7__cls-6"
            d="M922.2 451.47l1.48-.36.02-4.35-1.49.36-.01 4.35zM919.38 452.17l1.48-.37.02-4.35-1.49.37-.01 4.35zM912.84 453.77l1.49-.36.01-4.35-1.49.37-.01 4.34zM910.02 454.47l1.48-.37.01-4.35-1.48.37-.01 4.35z"
          />
          <path
            className="water_slide_7__cls-10"
            d="M908.8 450.76l18.89-5a8 8 0 005.71-9.8l-.06-.2a8 8 0 00-9.77-5.72l-18.89 5a8 8 0 00-5.71 9.8v.2a8 8 0 009.83 5.72z"
          />
          <g clipPath="url(#water_slide_7__clip-path-5)">
            <path
              className="water_slide_7__cls-21"
              d="M939.17 441.58a6.93 6.93 0 01-11.7 0l-.77.48a7.83 7.83 0 0013.23 0z"
            />
          </g>
          <g
            clipPath="url(#water_slide_7__clip-path-6)"
            id="water_slide_7__eye-3"
            data-name="eye"
          >
            <path
              className="water_slide_7__cls-20"
              d="M922.35 435.31a4.28 4.28 0 113.21 5.19 4.3 4.3 0 01-3.21-5.19"
            />
            <path
              className="water_slide_7__cls-21"
              d="M926.52 436.26a4.28 4.28 0 113.21 5.19 4.3 4.3 0 01-3.21-5.19"
            />
          </g>
          <path
            className="water_slide_7__cls-2"
            d="M910.88 450.21l11.4-3.01-4.12-15.72-11.4 3.01 4.12 15.72z"
          />
          <g>
            <path
              d="M889.84 421.63l1.63-.43a16.49 16.49 0 0120.14 11.8l.06.21-1.64.43a16.49 16.49 0 01-20.14-11.79z"
              fill="#ed8c75"
            />
          </g>
          <g>
            <path
              className="water_slide_7__cls-6"
              d="M921.14 411.86l.43 1.63a16.52 16.52 0 01-11.75 20.19l-.21.06-.43-1.64a16.53 16.53 0 0111.75-20.19z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterNoAnimationSlide7;
