import * as React from 'react';

function WaterNoAnimationSlide2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {'.water_slide_2__cls-3{fill:#b9dbfa}.water_slide_2__cls-4{fill:#6071ea}'}
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="water_slide_2__Layer_1" data-name="Layer 1">
          <g id="water_slide_2__float">
            <path
              className="water_slide_2__cls-3"
              d="M559.92 199.46h-13.31c-2.6-10.48-11.69-18.24-22.48-18.24h-7.07c-10.78 0-19.87 7.76-22.48 18.24h-.11c-15 0-27.17 12.8-27.17 28.54a2 2 0 001.91 2h116a2 2 0 001.91-2c-.02-15.74-12.21-28.54-27.2-28.54z"
            />
            <g id="water_slide_2__rain">
              <path
                id="water_slide_2__rain_drop"
                className="water_slide_2__cls-4"
                d="M499.8 242.26a.43.43 0 00-.62.18c-.67 1.47-2.69 6.41-1.58 10.71 1.18 4.52 4.35 4.49 6.3 3.91s4.52-2.79 3.11-6.73c-1.5-4.18-5.86-7.21-7.21-8.07z"
              />
              <path
                id="water_slide_2__rain_drop-2"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M524 242.26a.43.43 0 00-.62.18c-.67 1.47-2.69 6.41-1.57 10.71 1.17 4.52 4.35 4.49 6.29 3.91s4.52-2.79 3.11-6.73c-1.47-4.18-5.83-7.21-7.21-8.07z"
              />
              <path
                id="water_slide_2__rain_drop-5"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M549.55 242.26a.41.41 0 00-.61.18c-.68 1.47-2.7 6.41-1.58 10.71 1.17 4.52 4.35 4.49 6.29 3.91s4.52-2.79 3.11-6.73c-1.5-4.18-5.85-7.21-7.21-8.07z"
              />
              <path
                id="water_slide_2__rain_drop"
                className="water_slide_2__cls-4"
                d="M499.8 242.26a.43.43 0 00-.62.18c-.67 1.47-2.69 6.41-1.58 10.71 1.18 4.52 4.35 4.49 6.3 3.91s4.52-2.79 3.11-6.73c-1.5-4.18-5.86-7.21-7.21-8.07z"
              />
              <path
                id="water_slide_2__rain_drop-2"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M524 242.26a.43.43 0 00-.62.18c-.67 1.47-2.69 6.41-1.57 10.71 1.17 4.52 4.35 4.49 6.29 3.91s4.52-2.79 3.11-6.73c-1.47-4.18-5.83-7.21-7.21-8.07z"
              />
              <path
                id="water_slide_2__rain_drop-5"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M549.55 242.26a.41.41 0 00-.61.18c-.68 1.47-2.7 6.41-1.58 10.71 1.17 4.52 4.35 4.49 6.29 3.91s4.52-2.79 3.11-6.73c-1.5-4.18-5.85-7.21-7.21-8.07z"
              />
            </g>
            <path
              className="water_slide_2__cls-3"
              d="M839.63 259h-12.72c-2.49-10-11.18-17.43-21.49-17.43h-6.76c-10.31 0-19 7.41-21.49 17.43h-.11c-14.32 0-26 12.24-26 27.29a1.88 1.88 0 001.83 1.92h110.89a1.88 1.88 0 001.83-1.92c0-15.01-11.61-27.29-25.98-27.29z"
            />
            <g id="water_slide_2__rain-2" data-name="rain">
              <path
                id="water_slide_2__rain_drop-6"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M776.77 299.38a.42.42 0 00-.61.18c-.68 1.47-2.7 6.42-1.58 10.72 1.17 4.52 4.35 4.48 6.29 3.9s4.52-2.78 3.11-6.72c-1.5-4.18-5.85-7.21-7.21-8.08z"
              />
              <path
                id="water_slide_2__rain_drop-7"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M801 299.38a.42.42 0 00-.61.18c-.67 1.47-2.7 6.42-1.58 10.72 1.18 4.52 4.35 4.48 6.29 3.9s4.53-2.78 3.12-6.72c-1.5-4.18-5.86-7.21-7.22-8.08z"
              />
              <path
                id="water_slide_2__rain_drop-10"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M826.52 299.38a.42.42 0 00-.61.18c-.67 1.47-2.7 6.42-1.58 10.72 1.18 4.52 4.35 4.48 6.3 3.9s4.52-2.78 3.11-6.72c-1.5-4.18-5.86-7.21-7.22-8.08z"
              />
              <path
                id="water_slide_2__rain_drop-6"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M776.77 299.38a.42.42 0 00-.61.18c-.68 1.47-2.7 6.42-1.58 10.72 1.17 4.52 4.35 4.48 6.29 3.9s4.52-2.78 3.11-6.72c-1.5-4.18-5.85-7.21-7.21-8.08z"
              />
              <path
                id="water_slide_2__rain_drop-7"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M801 299.38a.42.42 0 00-.61.18c-.67 1.47-2.7 6.42-1.58 10.72 1.18 4.52 4.35 4.48 6.29 3.9s4.53-2.78 3.12-6.72c-1.5-4.18-5.86-7.21-7.22-8.08z"
              />
              <path
                id="water_slide_2__rain_drop-10"
                data-name="rain_drop"
                className="water_slide_2__cls-4"
                d="M826.52 299.38a.42.42 0 00-.61.18c-.67 1.47-2.7 6.42-1.58 10.72 1.18 4.52 4.35 4.48 6.3 3.9s4.52-2.78 3.11-6.72c-1.5-4.18-5.86-7.21-7.22-8.08z"
              />
            </g>
            <path
              className="water_slide_2__cls-3"
              d="M194.24 140.5h-8c-1.56-6.71-7-11.68-13.46-11.68h-4.24c-6.46 0-11.91 5-13.47 11.68H155c-9 0-16.28 8.2-16.28 18.29a1.21 1.21 0 001.14 1.28h69.48a1.22 1.22 0 001.15-1.28c.03-10.09-7.28-18.29-16.25-18.29z"
            />
            <g
              id="water_slide_2__snowflake"
            >
              <rect
                className="water_slide_2__cls-3"
                x={160.24}
                y={195.18}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 167.884 196.323)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={154.59}
                y={191.72}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 162.228 192.844)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={161.27}
                y={178.59}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 162.4 186.234)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={160.58}
                y={181.93}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 168.23 183.075)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={166.23}
                y={185.39}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 173.867 186.524)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={172.57}
                y={185.51}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 173.706 193.155)"
              />
            </g>
            <g
              id="water_slide_2__snowflake-3"
              data-name="snowflake"
            >
              <rect
                className="water_slide_2__cls-3"
                x={216.06}
                y={203.15}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 223.705 204.286)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={210.41}
                y={199.69}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 218.046 200.83)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={217.09}
                y={186.55}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 218.229 194.202)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={216.4}
                y={189.9}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 224.046 191.043)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={222.05}
                y={193.36}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 229.69 194.49)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={228.39}
                y={193.47}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 229.522 201.127)"
              />
            </g>
            <g
              id="water_slide_2__snowflake"
            >
              <rect
                className="water_slide_2__cls-3"
                x={160.24}
                y={195.18}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 167.884 196.323)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={154.59}
                y={191.72}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 162.228 192.844)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={161.27}
                y={178.59}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 162.4 186.234)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={160.58}
                y={181.93}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 168.23 183.075)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={166.23}
                y={185.39}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 173.867 186.524)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={172.57}
                y={185.51}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 173.706 193.155)"
              />
            </g>
            <g
              id="water_slide_2__snowflake-3"
              data-name="snowflake"
            >
              <rect
                className="water_slide_2__cls-3"
                x={216.06}
                y={203.15}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 223.705 204.286)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={210.41}
                y={199.69}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 218.046 200.83)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={217.09}
                y={186.55}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 218.229 194.202)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={216.4}
                y={189.9}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-88.52 224.046 191.043)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={222.05}
                y={193.36}
                width={15.3}
                height={2.28}
                rx={1.08}
                transform="rotate(-28.52 229.69 194.49)"
              />
              <rect
                className="water_slide_2__cls-3"
                x={228.39}
                y={193.47}
                width={2.28}
                height={15.3}
                rx={1.08}
                transform="rotate(-58.52 229.522 201.127)"
              />
            </g>
            <g
              id="water_slide_2__windmill"
              transform="translate(0, 5)"
            >
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1175.48"
                  y="375.56"
                  width="7.83"
                  height="51.81"
                  rx="3.09"
                />
              </g>
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1199.08"
                  y="416.39"
                  width="7.83"
                  height="51.81"
                  rx="3.09"
                  transform="translate(218.46 1262.97) rotate(-60)"
                />
              </g>
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1129.88"
                  y="438.38"
                  width="51.81"
                  height="7.83"
                  rx="3.09"
                  transform="translate(-66.3 637.15) rotate(-30)"
                />
              </g>
            </g>
            <g
              id="water_slide_2__windmill-2"
              transform="translate(0, 5)"
              data-name="windmill"
            >
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1310.82"
                  y="314.95"
                  width="7.83"
                  height="51.81"
                  rx="3.09"
                />
              </g>
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1334.43"
                  y="355.79"
                  width="7.83"
                  height="51.81"
                  rx="3.09"
                  transform="translate(338.61 1349.88) rotate(-60)"
                />
              </g>
              <g>
                <rect
                  className="water_slide_2__cls-3"
                  x="1265.23"
                  y="377.78"
                  width="51.81"
                  height="7.83"
                  rx="3.09"
                  transform="translate(-17.87 696.7) rotate(-30)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterNoAnimationSlide2;
