import * as React from 'react';

function WaterNoAnimationSlide7(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="water-shadow-slide-5" colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="10" floodOpacity="0.5" />
        </filter>
        <clipPath id="water_slide_5__clip-path">
          <path className="water_slide_5__cls-1" d="M0 0h1440v797H0z" />
        </clipPath>
        <clipPath id="water_slide_5__clip-path-2">
          <path
            id="water_slide_5__eyes"
            className="water_slide_5__cls-2"
            d="M810.87 300.83a3.63 3.63 0 003.6 3.09 2.81 2.81 0 002.66-3.31 3.63 3.63 0 00-3.57-3.11 2.81 2.81 0 00-2.69 3.33z"
          />
        </clipPath>
        <clipPath id="water_slide_5__clip-path-3">
          <path
            id="water_slide_5__eyes-2"
            data-name="eyes"
            className="water_slide_5__cls-2"
            d="M934.73 286.76a2.57 2.57 0 002.55 2.19 2 2 0 001.88-2.35 2.56 2.56 0 00-2.53-2.2 2 2 0 00-1.9 2.36z"
          />
        </clipPath>
        <clipPath id="water_slide_5__clip-path-4">
          <path
            className="water_slide_5__cls-1"
            d="M456.95 416.1l-63.11.84 7.98 31.97 56.3-2.14-1.17-30.67z"
          />
        </clipPath>
        <clipPath id="water_slide_5__clip-path-5">
          <path
            className="water_slide_5__cls-1"
            d="M357.03 419.92l-63.12.84 7.99 31.97 56.29-2.15-1.16-30.66z"
          />
        </clipPath>
        <linearGradient
          id="water_slide_5__linear-gradient"
          x1={323.05}
          y1={260.4}
          x2={323.74}
          y2={321.75}
          gradientTransform="translate(48.36 91.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#8bd5d8" />
          <stop offset={1} stopColor="#c6ffff" />
        </linearGradient>
        <linearGradient
          id="water_slide_5__linear-gradient-2"
          x1={263.19}
          y1={265.58}
          x2={261.15}
          y2={322.84}
          xlinkHref="#water_slide_5__linear-gradient"
        />
        <linearGradient
          id="water_slide_5__linear-gradient-3"
          x1={335.78}
          y1={272.2}
          x2={335.78}
          y2={323.81}
          gradientTransform="translate(48.36 91.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#b2ecf2" />
          <stop offset={1} stopColor="#def2fb" />
        </linearGradient>
        <style>
          {
            '.water_slide_5__cls-1{fill:none}.water_slide_5__cls-2{fill:#fff}.water_slide_5__cls-13{mix-blend-mode:multiply}.water_slide_5__cls-7{fill:#c060c7}.water_slide_5__cls-8{fill:#db73d7}.water_slide_5__cls-9{fill:#f5acea}.water_slide_5__cls-11{fill:#f4dc5c}.water_slide_5__cls-13{opacity:.5}.water_slide_5__cls-14{fill:#de539a}.water_slide_5__cls-20{fill:#b7579d}.water_slide_5__cls-25{fill:#6a88ed}.water_slide_5__cls-26{fill:#6cc997}.water_slide_5__cls-27{fill:#42ab84}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          clipPath="url(#water_slide_5__clip-path)"
          id="water_slide_5__Layer_1"
          data-name="Layer 1"
        >
          <g id="water_slide_5___5" data-name={5}>
            <g id="water_slide_5__float">
              <path
                d="M503.67 288.26h-13.48a23.57 23.57 0 00-22.76-17.63h-7.16a23.57 23.57 0 00-22.76 17.63h-.12a27.58 27.58 0 00-27.52 27.57 1.94 1.94 0 001.94 1.95h117.44a1.94 1.94 0 001.94-1.95 27.58 27.58 0 00-27.52-27.57z"
                fill="#dff2fb"
                id="water_slide_5__float-2"
                data-name="float"
              />
              <g className="rain">
                <path
                  d="M847.22 382h-11.07c-2.17-8.74-9.74-15.21-18.71-15.21h-5.88c-9 0-16.54 6.47-18.71 15.21h-.09c-12.48 0-22.62 10.68-22.62 23.81a1.63 1.63 0 001.59 1.67h96.52a1.63 1.63 0 001.59-1.67c0-13.16-10.14-23.81-22.62-23.81z"
                  fill="#def2fd"
                />
                <g id="water_slide_5__rain_drop\xE5ber">
                  <path
                    id="water_slide_5__rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M797.54 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.12 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.46-3.97-5.64-6.85-6.92-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-2"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M820.48 417.74a.4.4 0 00-.58.18c-.64 1.39-2.55 6.08-1.49 10.16 1.11 4.29 4.12 4.26 6 3.7a4.61 4.61 0 002.94-6.37c-1.46-3.97-5.58-6.85-6.87-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-5"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M844.65 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.11 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.51-3.97-5.64-6.85-6.92-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M797.54 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.12 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.46-3.97-5.64-6.85-6.92-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-2"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M820.48 417.74a.4.4 0 00-.58.18c-.64 1.39-2.55 6.08-1.49 10.16 1.11 4.29 4.12 4.26 6 3.7a4.61 4.61 0 002.94-6.37c-1.46-3.97-5.58-6.85-6.87-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-5"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M844.65 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.11 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.51-3.97-5.64-6.85-6.92-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M797.54 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.12 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.46-3.97-5.64-6.85-6.92-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-2"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M820.48 417.74a.4.4 0 00-.58.18c-.64 1.39-2.55 6.08-1.49 10.16 1.11 4.29 4.12 4.26 6 3.7a4.61 4.61 0 002.94-6.37c-1.46-3.97-5.58-6.85-6.87-7.67z"
                  />
                  <path
                    id="water_slide_5__rain_drop\xE5be-5"
                    data-name="rain_drop\xE5be"
                    className="water_slide_5__cls-25"
                    d="M844.65 417.74a.4.4 0 00-.58.18c-.64 1.39-2.56 6.08-1.5 10.16 1.11 4.29 4.12 4.26 6 3.7a4.62 4.62 0 003-6.37c-1.51-3.97-5.64-6.85-6.92-7.67z"
                  />
                </g>
              </g>
              <g
                id="water_slide_5__bird-3"
                data-name="bird"
              >
                <g>
                  <path
                    className="water_slide_5__cls-7"
                    d="M830.46 295A26.56 26.56 0 00816 280.76a.57.57 0 00-.49.05.58.58 0 00-.24.43l-1 28.5a.57.57 0 00.16.41.56.56 0 00.41.15l17.07-1.05h.14a.56.56 0 00.36-.45l.12-1a24.57 24.57 0 00-2.07-12.8z"
                  />
                </g>
                <path
                  className="water_slide_5__cls-8"
                  d="M824.41 299.49L810.07 296a.5.5 0 00-.43.08.51.51 0 00-.23.38l-1.25 10.05v.56a10.66 10.66 0 008.35 10.87l22.85 4.76a.58.58 0 00.29 0 .42.42 0 00.13-.07.51.51 0 00.22-.36 19.37 19.37 0 00.08-5 21 21 0 00-15.67-17.78z"
                />
                <path
                  className="water_slide_5__cls-9"
                  d="M824.51 310.08l-15.74-3.54a.57.57 0 00-.44.09.54.54 0 00-.22.4 10.66 10.66 0 008.35 10.87l14.7 3.06a.45.45 0 00.28 0 .59.59 0 00.14-.06.55.55 0 00.22-.37 9.56 9.56 0 00-7.29-10.45z"
                />
                <g>
                  <path
                    className="water_slide_5__cls-8"
                    d="M827.65 280.7a.55.55 0 00-.48-.09.52.52 0 00-.36.32l-9.76 25.41a.56.56 0 000 .44.55.55 0 00.34.27l16.83 4.53a.5.5 0 00.32 0 .52.52 0 00.3-.28l.42-.86a22.63 22.63 0 001.89-12.34 26.31 26.31 0 00-9.5-17.4z"
                  />
                </g>
                <g
                  id="water_slide_5__general_eye_shape-3"
                  data-name="general eye shape"
                >
                  <g id="water_slide_5__EYE-3" data-name="EYE">
                    <path
                      id="water_slide_5__eyes-3"
                      data-name="eyes"
                      className="water_slide_5__cls-2"
                      d="M810.87 300.83a3.63 3.63 0 003.6 3.09 2.81 2.81 0 002.66-3.31 3.63 3.63 0 00-3.57-3.11 2.81 2.81 0 00-2.69 3.33z"
                    />
                    <g clipPath="url(#water_slide_5__clip-path-2)">
                      <path
                        id="water_slide_5__eyes-4"
                        data-name="eyes"
                        d="M808.87 300a3.67 3.67 0 003.64 3.1 2.8 2.8 0 002.67-3.32 3.67 3.67 0 00-3.61-3.13 2.82 2.82 0 00-2.7 3.35z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="water_slide_5__n\xE6b"
                  className="water_slide_5__cls-11"
                  d="M803.83 299.07l5.11 1.22.3-2.41-5.12-1.25a1 1 0 00-1.28.85v.2a1.32 1.32 0 00.99 1.39z"
                />
              </g>
              <g
                id="water_slide_5__bird-4"
                data-name="bird"
              >
                <g>
                  <path
                    className="water_slide_5__cls-7"
                    d="M948.62 282.6a18.88 18.88 0 00-10.26-10.08.37.37 0 00-.34 0 .36.36 0 00-.17.31l-.74 20.21a.38.38 0 00.12.28.36.36 0 00.29.11l12.1-.74h.1a.39.39 0 00.26-.32l.08-.7a17.45 17.45 0 00-1.44-9.07z"
                  />
                </g>
                <path
                  className="water_slide_5__cls-8"
                  d="M944.33 285.8l-10.17-2.51a.42.42 0 00-.31.06.37.37 0 00-.16.27l-.88 7.13v.4a7.55 7.55 0 005.92 7.7l16.2 3.38a.44.44 0 00.2 0l.1-.05a.44.44 0 00.16-.26 13.52 13.52 0 000-3.54 14.93 14.93 0 00-11.06-12.58z"
                />
                <path
                  className="water_slide_5__cls-9"
                  d="M944.4 293.32l-11.16-2.51a.4.4 0 00-.32.06.4.4 0 00-.15.29 7.55 7.55 0 005.92 7.7l10.42 2.14a.34.34 0 00.2 0 .31.31 0 00.1 0 .42.42 0 00.16-.26 6.77 6.77 0 00-5.17-7.42z"
                />
                <g>
                  <path
                    className="water_slide_5__cls-8"
                    d="M946.62 272.48a.37.37 0 00-.34-.06.38.38 0 00-.25.23l-6.92 18a.4.4 0 000 .32.39.39 0 00.24.19l11.93 3.21a.36.36 0 00.23 0 .41.41 0 00.22-.19l.29-.61a16 16 0 001.34-8.75 18.65 18.65 0 00-6.74-12.34z"
                  />
                </g>
                <g
                  id="water_slide_5__general_eye_shape-4"
                  data-name="general eye shape"
                >
                  <g id="water_slide_5__EYE-4" data-name="EYE">
                    <path
                      id="water_slide_5__eyes-5"
                      data-name="eyes"
                      className="water_slide_5__cls-2"
                      d="M934.73 286.76a2.57 2.57 0 002.55 2.19 2 2 0 001.88-2.35 2.56 2.56 0 00-2.53-2.2 2 2 0 00-1.9 2.36z"
                    />
                    <g clipPath="url(#water_slide_5__clip-path-3)">
                      <path
                        id="water_slide_5__eyes-6"
                        data-name="eyes"
                        d="M933.31 286.16a2.59 2.59 0 002.58 2.2 2 2 0 001.89-2.35 2.6 2.6 0 00-2.56-2.22 2 2 0 00-1.91 2.37z"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="water_slide_5__n\xE6b-2"
                  data-name="n\xE6b"
                  className="water_slide_5__cls-11"
                  d="M929.73 285.51l3.63.87.21-1.72-3.63-.88a.71.71 0 00-.91.6v.14a.94.94 0 00.7.99z"
                />
              </g>
            </g>
            <g
              id="water_slide_5__small_car"
              data-name="small car"
            >
              <g id="water_slide_5__car">
                <path
                  className="water_slide_5__cls-14"
                  d="M472.31 431.43l.2 5.27a9.49 9.49 0 01-9.12 9.86l-164.08 6.27a9.49 9.49 0 01-9.84-9.14l-.21-5.27a9.51 9.51 0 009.85 9.14l164.08-6.27a9.5 9.5 0 009.12-9.86z"
                />
                <path
                  d="M445.75 393.07l-74.54 2.84-1.51-39.65 10.62-.41-1.19-1.39a7.63 7.63 0 00-6.1-2.64l-3.49.14-42.75 1.63-24.24.92a7.51 7.51 0 00-7.12 6l-7.66 38.63 1.5 39.32a9.49 9.49 0 009.84 9.14l164.08-6.27a9.5 9.5 0 009.12-9.86l-.54-14.21a25.09 25.09 0 00-26.02-24.19zm-150.36.45l5.82-31.34a4.13 4.13 0 013.83-3.45l21.91-.84 1.51 39.66-28.92 1.1a4.24 4.24 0 01-4.15-5.13z"
                  fill="#ef76b8"
                />
                <path
                  d="M413.29 394.31l-53.51 2a5.41 5.41 0 01-5.6-5.2l-1.1-28.84a5.42 5.42 0 015.19-5.62l22.05-.84.64.76 29 33.78z"
                  fill="url(#water_slide_5__linear-gradient)"
                />
                <path
                  d="M346.7 361.57l1.17 30.8a4.43 4.43 0 01-4.25 4.6l-44.08 1.68a4.24 4.24 0 01-4.15-5.13l5.82-31.34a4.13 4.13 0 013.83-3.45l37.07-1.42a4.43 4.43 0 014.59 4.26z"
                  fill="url(#water_slide_5__linear-gradient-2)"
                />
                <path
                  fill="url(#water_slide_5__linear-gradient-3)"
                  d="M409.93 390.39l-28.97-33.78-.64-.76h.01l29.6 34.54z"
                />
              </g>
              <g clipPath="url(#water_slide_5__clip-path-4)">
                <ellipse
                  className="water_slide_5__cls-14"
                  cx={428.58}
                  cy={444.97}
                  rx={26.49}
                  ry={25.69}
                  transform="rotate(-2.33 429.02 445.326)"
                />
              </g>
              <g id="water_slide_5__hjul">
                <ellipse
                  className="water_slide_5__cls-20"
                  cx={428.57}
                  cy={444.8}
                  rx={23.25}
                  ry={22.55}
                  transform="rotate(-2.33 428.78 445.075)"
                />
                <ellipse
                  className="water_slide_5__cls-14"
                  cx={428.58}
                  cy={444.97}
                  rx={14.31}
                  ry={13.87}
                  transform="rotate(-2.33 429.02 445.326)"
                />
              </g>
              <g clipPath="url(#water_slide_5__clip-path-5)">
                <ellipse
                  className="water_slide_5__cls-14"
                  cx={328.65}
                  cy={448.79}
                  rx={26.49}
                  ry={25.69}
                  transform="rotate(-2.33 328.831 449.191)"
                />
              </g>
              <g id="water_slide_5__hjul-2" data-name="hjul">
                <ellipse
                  className="water_slide_5__cls-20"
                  cx={328.65}
                  cy={448.62}
                  rx={23.25}
                  ry={22.55}
                  transform="rotate(-2.33 328.836 448.946)"
                />
                <ellipse
                  className="water_slide_5__cls-14"
                  cx={328.65}
                  cy={448.79}
                  rx={14.31}
                  ry={13.87}
                  transform="rotate(-2.33 328.831 449.191)"
                />
              </g>
              <path
                d="M440.11 400.3l16.06-.61a9.31 9.31 0 019.67 9l.12 3.06-22.84.87a2.55 2.55 0 01-2.64-2.45z"
                fill="#ffe67a"
              />
            </g>

            <g
              filter="url(#water-shadow-slide-5)"
              id="water_slide_5__FG"
            >
              <path
                className="water_slide_5__cls-26"
                d="M-9.95-6.8l-8 819 1480.62-5.12L1459-11.18zm1391.8 448.63c-12.56 111.32-64 295.8-299 318s-247-65.25-418.22-65.25-161 34-339.75 43S98.22 523.93 88.79 407.82 173 68.91 399.06 134.18c84 24.26 220.42 53.09 335.76 34.67C929.79 137.7 849.2 64 1120.75 84.48s273.66 246.04 261.1 357.35z"
              />
              <path
                className="water_slide_5__cls-26"
                d="M267.51 680.05h6.01v63.51h-6.01z"
              />
              <path
                className="water_slide_5__cls-26"
                d="M256.11 685v-5.6h-4v5.6c0 11.94 10.1 18 20.32 18.11v-3.47c-8.19-.11-16.32-4.99-16.32-14.64zM287.07 677v-6h3.52v6c0 12.74-9 19.18-18.11 19.32v-3.7c7.34-.15 14.59-5.35 14.59-15.62z"
              />
              <path
                className="water_slide_5__cls-26"
                d="M300.17 643.94h-3.41v-.22a10.87 10.87 0 00-10.87-10.89h-24.73a10.87 10.87 0 00-10.87 10.89v.22h-9.13a10 10 0 00-10 10v17a10 10 0 0010 10h59a10 10 0 0010-10V654a10 10 0 00-9.99-10.06z"
              />
            </g>
            <g
              filter="url(#water-shadow-slide-5)"
              id="water_slide_5__FG-2"
              data-name="FG"
            >
              <path
                className="water_slide_5__cls-27"
                d="M-46-22.42v889h1584.66v-889zm1472 448.6c-13.82 124.37-50.68 332.81-309.26 357.63s-271.6-40.25-460-40.25S478.31 764 281.63 774 49.18 525.55 38.8 395.82 144.66 3.12 393.41 76c92.45 27.11 187.81 43.69 314.74 23.12 214.56-34.8 145.27-96.64 444.1-73.75S1439.77 301.82 1426 426.18z"
              />
              <path
                className="water_slide_5__cls-27"
                d="M1187.1 680.86h10.98v108.58h-10.98z"
              />
              <path
                className="water_slide_5__cls-27"
                d="M1166.29 689.36v-9.61h-7.22v9.61c0 20.42 18.44 30.74 37.09 31v-5.93c-15.04-.26-29.87-8.61-29.87-25.07zM1222.8 675.63v-10.24h6.43v10.24c0 21.78-16.44 32.79-33.07 33v-6.33c13.41-.2 26.64-9.11 26.64-26.67z"
              />
              <path
                className="water_slide_5__cls-27"
                d="M1246.73 619.11h-6.23v-.37c0-10.29-8.89-18.63-19.85-18.63h-45.15c-11 0-19.85 8.34-19.85 18.63v.37H1139c-10.07 0-18.23 7.66-18.23 17.12v29.1c0 9.45 8.16 17.11 18.23 17.11h107.75c10.07 0 18.23-7.66 18.23-17.11v-29.1c.02-9.46-8.18-17.12-18.25-17.12z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterNoAnimationSlide7;
