import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            d="M159.19 99.75l-.88-1.06A80.67 80.67 0 00132 77.55a71.71 71.71 0 00-61.1-1.45A80.82 80.82 0 0043.5 96l-.93 1a2.25 2.25 0 00-.07 2.87l.88 1.06a80.57 80.57 0 0026.35 21.14 71.71 71.71 0 0061.1 1.45 81 81 0 0027.36-19.87l.93-1a2.26 2.26 0 00.07-2.87z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-7{fill:none}.prefix__cls-2{fill:#199b69}.prefix__cls-3{fill:#2bb673}.prefix__cls-7{stroke:#116043;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}'
          }
        </style>
      </defs>
      <path
        className="prefix__cls-2"
        d="M127.35 41.62a26.1 26.1 0 01-26.1 26.1 26.1 26.1 0 0126.1-26.1z"
      />
      <path
        className="prefix__cls-3"
        d="M72.15 34a33.76 33.76 0 0133.75 33.72A33.76 33.76 0 0172.15 34z"
        transform="rotate(-180 89.025 50.84)"
      />
      <rect
        className="prefix__cls-3"
        x={82.07}
        y={106.19}
        width={35.86}
        height={59.76}
        rx={17.93}
      />
      <path
        className="prefix__cls-2"
        d="M82.07 141.75v-23.91h35.86v23.91s-6.64 3.45-17.93 3.45-17.93-3.45-17.93-3.45z"
      />
      <path
        id="prefix__hrad"
        className="prefix__cls-3"
        d="M170.41 97.64l-7-7.35a89.11 89.11 0 00-126.3-3.06l-7.32 7a5.83 5.83 0 00-.2 8.21l7 7.35a88.63 88.63 0 0062.51 27.79 89.7 89.7 0 0017.46-1.27 88.56 88.56 0 0046.33-23.46l7.32-7a5.83 5.83 0 00.2-8.21z"
      />
      <g clipPath="url(#prefix__clip-path)">
        <path
          className="prefix__cls-2"
          d="M159.19 99.75l-.88-1.06A80.67 80.67 0 00132 77.55a71.71 71.71 0 00-61.1-1.45A80.82 80.82 0 0043.5 96l-.93 1a2.25 2.25 0 00-.07 2.87l.88 1.06a80.57 80.57 0 0026.35 21.14 71.71 71.71 0 0061.1 1.45 81 81 0 0027.36-19.87l.93-1a2.26 2.26 0 00.07-2.87z"
        />
      </g>
      <path
        d="M157.92 101.2l-.86-1a78.91 78.91 0 00-25.82-20.39 71.22 71.22 0 00-59.81-1.45 78.8 78.8 0 00-26.77 19.1l-.91 1a2.12 2.12 0 00-.07 2.76l.86 1a79.18 79.18 0 0025.82 20.4 71.24 71.24 0 0059.82 1.45A79.07 79.07 0 00157 105l.91-1a2.14 2.14 0 00.06-2.77z"
        fill="#beeda2"
      />
      <path
        d="M99.06 121.48a10.1 10.1 0 01-5.69-1.48 4.83 4.83 0 01-2.06-3.16 7.78 7.78 0 011.62-6 8.77 8.77 0 0115.28 3.6c.28 1.67.2 5.7-6.71 6.89a14.54 14.54 0 01-2.44.15z"
        fill="#116043"
      />
      <g id="prefix__hand">
        <path
          className="prefix__cls-3"
          d="M28.51 141.58c0 6.79 7.91 12.31 17.71 12.35C56 154 64 148.54 64 141.76z"
        />
        <path
          className="prefix__cls-3"
          d="M56.73 132.68c-5.22-2.53-11.61-.12-11.61-.12s2.07 6.5 7.3 9 11.6.12 11.6.12-2.02-6.47-7.29-9z"
        />
      </g>
      <path
        className="prefix__cls-3"
        d="M128.88 141.92A24.12 24.12 0 01153 166a24.12 24.12 0 01-24.12-24.12v.04z"
        transform="rotate(-180 140.94 153.98)"
      />
      <path className="prefix__cls-7" d="M125.62 93.71v8.28M75.83 93.71v8.28" />
    </svg>
  );
}

export default SvgComponent;
