import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <filter id="shadow-eboti-15" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M157.27 134.55a168.45 168.45 0 00-18.13 10.54.46.46 0 00-.17.53c.63 1.64 1.75 2.75-.83 5.06-2.75 2.46-5.3.68-6.55-.1-3.61 3-6.16 5.86-6.25 8.09-.35 8 7.87 21.57 17.77 22.62 8.08.86 11.73-3.42 16.34-7.7a.5.5 0 00.12-.58c-.75-1.74-2.22-4.72.94-6.64s5-.87 7.3.39a.41.41 0 00.44 0c7.92-5.8 14.9-11.29 12-16.29a86.4 86.4 0 00-7.07-9.9c-3.98-5.12-7.76-7.69-15.91-6.02z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            className="prefix__cls-2"
            d="M112.07 123.65v14.16c0 11.19-8 20.27-17.81 20.29-9.86 0-17.86-9-17.88-20.24V123.7c0-9.77 7-17.71 15.56-17.72h4.52c8.61.02 15.6 7.9 15.61 17.67z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-3">
          <path
            className="prefix__cls-1"
            d="M149.09 98.37l-.69-1.19A81 81 0 00125.85 72a71.72 71.72 0 00-60-11.39 80.88 80.88 0 00-30.28 15.16l-1.09.85a2.25 2.25 0 00-.48 2.82l.69 1.19a80.61 80.61 0 0022.55 25.15 71.66 71.66 0 0060 11.4A81.07 81.07 0 00147.47 102l1.08-.85a2.16 2.16 0 00.78-1.31 2.23 2.23 0 00-.24-1.47z"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1,.prefix__cls-10{fill:none}.prefix__cls-2{fill:#2bb673}.prefix__cls-5{fill:#d9ac4f}.prefix__cls-7{fill:#199b69}.prefix__cls-10{stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}'
          }
        </style>
      </defs>
      <g
        filter="url(#shadow-eboti-15)"
      >
        <path
          className="prefix__cls-2"
          d="M112.07 123.65v14.16c0 11.19-8 20.27-17.81 20.29-9.86 0-17.86-9-17.88-20.24V123.7c0-9.77 7-17.71 15.56-17.72h4.52c8.61.02 15.6 7.9 15.61 17.67z"
        />
        <g clipPath="url(#prefix__clip-path-2)">
          <path
            className="prefix__cls-7"
            d="M111.77 118.61l1.31 11A75.86 75.86 0 0174.82 134l-1.29-10.8c-1.24-10.47 5.22-19.85 14.44-21l4.84-.58c9.19-1.08 17.71 6.51 18.96 16.99z"
          />
        </g>
        <path
          className="prefix__cls-7"
          d="M116.88 37a21.53 21.53 0 01-21.52 21.55A21.53 21.53 0 01116.88 37z"
          transform="rotate(9.39 106.099 47.797)"
        />
        <path
          className="prefix__cls-2"
          d="M67.55 21.12a33.76 33.76 0 0133.76 33.76 33.76 33.76 0 01-33.76-33.76z"
          transform="rotate(-170.61 84.43 38.002)"
        />
        <path
          id="prefix__hrad"
          className="prefix__cls-2"
          d="M160.5 98.12l-5.68-8.38A89.09 89.09 0 0030.69 66.11l-8.36 5.71a5.83 5.83 0 00-1.54 8.07l5.68 8.38a88.67 88.67 0 0057.16 37.63 90.3 90.3 0 0017.43 1.59 88.53 88.53 0 0049.54-15.59l8.37-5.71a5.83 5.83 0 001.53-8.07z"
        />
        <g clipPath="url(#prefix__clip-path-3)">
          <path
            className="prefix__cls-7"
            d="M149.09 98.37l-.69-1.19A81 81 0 00125.85 72a71.72 71.72 0 00-60-11.39 80.88 80.88 0 00-30.28 15.16l-1.09.85a2.25 2.25 0 00-.48 2.82l.69 1.19a80.61 80.61 0 0022.55 25.15 71.66 71.66 0 0060 11.4A81.07 81.07 0 00147.47 102l1.08-.85a2.16 2.16 0 00.78-1.31 2.23 2.23 0 00-.24-1.47z"
          />
        </g>
        <path
          d="M147.61 99.6l-.69-1.15a79.14 79.14 0 00-22.14-24.33A71.2 71.2 0 0066 62.93a79.07 79.07 0 00-29.53 14.49l-1.06.82a2.14 2.14 0 00-.52 2.76l.69 1.15a79.14 79.14 0 0022.14 24.33 71.2 71.2 0 0058.78 11.19 79.07 79.07 0 0029.5-14.53l1.06-.82a2.17 2.17 0 00.52-2.72z"
          fill="#beeda2"
        />
        <g>
          <motion.path
            animate={{
              scale: [1, 0, 1],
            }}
            transition={{
              delay: 2, duration: 1, repeat: Infinity, repeatDelay: 4,
            }}
            className="prefix__cls-10"
            d="M73.61 73.33l-5.3 11.48"
          />
          <motion.path
            animate={{
              scale: [1, 0, 1],
            }}
            transition={{
              delay: 2, duration: 1, repeat: Infinity, repeatDelay: 4,
            }}
            className="prefix__cls-10"
            d="M116.41 82.86l-5.3 11.48"
          />
          <motion.path
            animate={{
              scaleY: [0.4, 1, 1, 0.4],
            }}
            transition={{
              delay: 2, duration: 4, times: [0, 0.5, 0.9, 1], repeat: Infinity, repeatDelay: 6,
            }}
            d="M74.79 97.46a81.7 81.7 0 0012 .56 46.22 46.22 0 0011.22-2.38 1.12 1.12 0 011.17.81c.81 2.86 2.48 11.74-7.94 12.49-9.61.7-15.28-6.47-17.35-9.75a1.12 1.12 0 01.9-1.73z"
            fill="#006838"
          />
        </g>

        <g>
          <path
            className="prefix__cls-2"
            d="M22.91 116.82A24.12 24.12 0 0147 140.93a24.12 24.12 0 01-24.12-24.12z"
            transform="rotate(156.17 34.966 128.877)"
          />
          <path
            className="prefix__cls-2"
            d="M36.26 116.29a16.93 16.93 0 0116.93 16.93 16.93 16.93 0 01-16.93-16.93z"
            transform="rotate(-165.86 44.736 124.756)"
          />
        </g>
      </g>
      <motion.g
        filter="url(#shadow-eboti-15)"
        className="hand"
        animate={{
          rotate: [0, 160, 130, 160, 130, 160, 0],
          x: [0, -50, 0],
        }}
        transition={{
          duration: 5, repeat: Infinity, repeatDelay: 5,
        }}
        style={{
          transformBox: 'fill-box',
          originY: 'top',
          originX: 'center',
        }}
      >
        <g clipPath="url(#prefix__clip-path)">
          <path
            d="M157.27 134.55s-31.59 16.15-31.93 24.12 7.87 21.57 17.77 22.62 13.16-5.63 19.68-10.54c9.67-7.28 21-14.17 17.48-20.31a86.4 86.4 0 00-7.07-9.9c-4-5.09-7.78-7.66-15.93-5.99z"
            fill="#f4d851"
          />
          <circle className="prefix__cls-5" cx={177.05} cy={154.18} r={7.72} />
          <circle className="prefix__cls-5" cx={147.38} cy={160.34} r={7.85} />
          <circle className="prefix__cls-5" cx={140.23} cy={182.26} r={7.65} />
          <circle className="prefix__cls-5" cx={163.04} cy={136.31} r={9.91} />
          <circle className="prefix__cls-5" cx={124.37} cy={157.77} r={7.04} />
        </g>
        <path
          className="prefix__cls-2"
          d="M125.33 133.6a24.12 24.12 0 0124.12 24.12 24.12 24.12 0 01-24.12-24.12z"
          transform="rotate(-180 137.385 145.665)"
        />
      </motion.g>
    </svg>
  );
}

export default SvgComponent;
