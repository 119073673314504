import * as React from 'react';

function WaterNoAnimationSlide1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <filter id="water-shadow-slide-1" colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.5" floodColor="#5565e8" />
        </filter>
        <style>
          {
            '.water_slide_1__cls-2{opacity:.5;mix-blend-mode:multiply}.water_slide_1__cls-3{fill:#e1fdff}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          filter="url(#water-shadow-slide-1)"
          id="water_slide_1__Layer_1"
          data-name="Layer 1"
        >
          <g id="water_slide_1__snow">
            <g
              className="snowflake"
            >
              <g id="water_slide_1___Radial_Repeat_" data-name="&lt;Radial Repeat&gt;">
                <path
                  className="water_slide_1__cls-3"
                  d="M1023.63 482.26a2.41 2.41 0 00-3 1.62l-10.58 35.43a2.41 2.41 0 001.62 3 2.41 2.41 0 003-1.62l10.57-35.43a2.4 2.4 0 00-1.61-3z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1018.11 505.5l-.07-.11a2.12 2.12 0 00-2.87-.87l-11.16 6a2.14 2.14 0 00-.86 2.89l.06.11a2.12 2.12 0 002.88.86l11.16-6a2.14 2.14 0 00.86-2.88z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1015.9 505.36l-.23.12a2.12 2.12 0 00-.86 2.87l5.57 10.34a2.11 2.11 0 002.86.85l.23-.12a2.12 2.12 0 00.86-2.87l-5.57-10.34a2.1 2.1 0 00-2.86-.85z"
                />
              </g>
              <g id="water_slide_1___Radial_Repeat_2" data-name="&lt;Radial Repeat&gt;">
                <rect
                  className="water_slide_1__cls-3"
                  x={989.57}
                  y={492.22}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1010.14 494.514)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1007.89 496.64l.07-.11a2.14 2.14 0 00-.7-2.93l-10.79-6.66a2.12 2.12 0 00-2.92.69l-.07.11a2.14 2.14 0 00.7 2.93l10.79 6.66a2.12 2.12 0 002.92-.69z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1006.91 494.65l-.22-.14a2.12 2.12 0 00-2.91.69l-6.15 10a2.12 2.12 0 00.69 2.92l.22.13a2.1 2.1 0 002.91-.69l6.15-10a2.12 2.12 0 00-.69-2.91z"
                />
              </g>
              <g id="water_slide_1___Radial_Repeat_3" data-name="&lt;Radial Repeat&gt;">
                <path
                  className="water_slide_1__cls-3"
                  d="M1027.77 499.76a2.43 2.43 0 00.1-3.42l-25.34-26.89a2.4 2.4 0 00-3.4-.1 2.42 2.42 0 00-.1 3.42l25.33 26.89a2.42 2.42 0 003.41.1z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1002.27}
                  y={472.69}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1010.71 474.884)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1001.45}
                  y={473.17}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1003.746 481.124)"
                />
              </g>
              <g id="water_slide_1___Radial_Repeat_4" data-name="&lt;Radial Repeat&gt;">
                <path
                  className="water_slide_1__cls-3"
                  d="M1017.69 502.15a2.41 2.41 0 003-1.62l10.57-35.43a2.41 2.41 0 00-1.62-3 2.41 2.41 0 00-3 1.62l-10.57 35.43a2.41 2.41 0 001.62 3z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1023.21 478.91l.06.12a2.12 2.12 0 002.87.86l11.16-6a2.13 2.13 0 00.86-2.88l-.06-.12a2.13 2.13 0 00-2.88-.86l-11.15 6a2.13 2.13 0 00-.86 2.88z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1025.42 479.05l.23-.12a2.12 2.12 0 00.85-2.87l-5.56-10.33a2.12 2.12 0 00-2.87-.86l-.23.12a2.12 2.12 0 00-.85 2.87l5.56 10.34a2.11 2.11 0 002.87.85z"
                />
              </g>
              <g id="water_slide_1___Radial_Repeat_5" data-name="&lt;Radial Repeat&gt;">
                <rect
                  className="water_slide_1__cls-3"
                  x={1010.01}
                  y={487.36}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1030.528 489.654)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1033.42 487.77l-.06.11a2.13 2.13 0 00.69 2.93l10.79 6.66a2.11 2.11 0 002.92-.69l.07-.11a2.14 2.14 0 00-.69-2.93l-10.8-6.66a2.12 2.12 0 00-2.92.69z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1034.4 489.76l.23.14a2.11 2.11 0 002.9-.69l6.16-10a2.12 2.12 0 00-.69-2.91l-.23-.14a2.09 2.09 0 00-2.9.69l-6.16 10a2.12 2.12 0 00.69 2.91z"
                />
              </g>
              <g id="water_slide_1___Radial_Repeat_6" data-name="&lt;Radial Repeat&gt;">
                <path
                  className="water_slide_1__cls-3"
                  d="M1013.54 484.66a2.42 2.42 0 00-.1 3.41l25.34 26.93a2.42 2.42 0 003.41.1 2.44 2.44 0 00.1-3.42L1017 484.75a2.41 2.41 0 00-3.46-.09z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1022.09}
                  y={507.35}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1030.525 509.554)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1035.36}
                  y={495.3}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1037.655 503.256)"
                />
              </g>
            </g>
            <g
              className="snowflake"
            >
              <g
                id="water_slide_1___Radial_Repeat_7"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1111.39 455.54a2.41 2.41 0 00-3 1.62l-10.58 35.43a2.41 2.41 0 001.62 3 2.4 2.4 0 003-1.62l10.57-35.42a2.41 2.41 0 00-1.61-3.01z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1105.87 478.78l-.07-.11a2.1 2.1 0 00-2.87-.86l-11.16 6a2.12 2.12 0 00-.86 2.88l.06.11a2.12 2.12 0 002.88.86l11.16-6a2.13 2.13 0 00.86-2.88z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1103.66 478.64l-.23.12a2.12 2.12 0 00-.86 2.87l5.57 10.34a2.1 2.1 0 002.86.85l.23-.12a2.12 2.12 0 00.86-2.87l-5.57-10.33a2.1 2.1 0 00-2.86-.86z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_8"
                data-name="&lt;Radial Repeat&gt;"
              >
                <rect
                  className="water_slide_1__cls-3"
                  x={1077.33}
                  y={465.5}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1097.837 467.809)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1095.65 469.92l.07-.11a2.14 2.14 0 00-.7-2.93l-10.79-6.66a2.12 2.12 0 00-2.92.69l-.07.12a2.12 2.12 0 00.7 2.92l10.79 6.67a2.13 2.13 0 002.92-.7z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1094.67 467.93l-.22-.13a2.1 2.1 0 00-2.91.69l-6.15 10a2.12 2.12 0 00.69 2.92l.22.14a2.12 2.12 0 002.91-.7l6.15-10a2.13 2.13 0 00-.69-2.92z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_9"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1115.53 473a2.43 2.43 0 00.1-3.42l-25.34-26.89a2.39 2.39 0 00-3.4-.09 2.41 2.41 0 00-.1 3.41l25.33 26.89a2.42 2.42 0 003.41.1z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1090.03}
                  y={445.97}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1098.472 448.168)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1089.21}
                  y={446.45}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1091.508 454.398)"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_10"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1105.45 475.43a2.4 2.4 0 003-1.62l10.55-35.42a2.42 2.42 0 00-1.62-3 2.41 2.41 0 00-3 1.62l-10.57 35.43a2.41 2.41 0 001.64 2.99z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1111 452.19l.06.12a2.12 2.12 0 002.87.86l11.16-6a2.13 2.13 0 00.86-2.88l-.06-.12a2.13 2.13 0 00-2.88-.86l-11.15 6a2.12 2.12 0 00-.86 2.88z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1113.18 452.34l.23-.13a2.12 2.12 0 00.85-2.87L1108.7 439a2.12 2.12 0 00-2.87-.86l-.23.12a2.12 2.12 0 00-.85 2.87l5.56 10.34a2.12 2.12 0 002.87.87z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_11"
                data-name="&lt;Radial Repeat&gt;"
              >
                <rect
                  className="water_slide_1__cls-3"
                  x={1097.77}
                  y={460.64}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1118.269 462.953)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1121.18 461.05l-.06.11a2.13 2.13 0 00.69 2.93l10.79 6.66a2.11 2.11 0 002.92-.69l.07-.11a2.14 2.14 0 00-.69-2.93l-10.8-6.66a2.12 2.12 0 00-2.92.69z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1122.16 463l.23.14a2.11 2.11 0 002.9-.69l6.15-10a2.11 2.11 0 00-.68-2.91l-.23-.14a2.11 2.11 0 00-2.9.69l-6.16 10a2.12 2.12 0 00.69 2.91z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_12"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1101.3 457.94a2.42 2.42 0 00-.1 3.41l25.34 26.89a2.42 2.42 0 003.41.1 2.43 2.43 0 00.1-3.42L1104.71 458a2.41 2.41 0 00-3.41-.06z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1109.85}
                  y={480.63}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1118.283 482.833)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1123.12}
                  y={468.58}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1125.423 476.535)"
                />
              </g>
            </g>
            <g
              className="snowflake"
            >
              <g
                id="water_slide_1___Radial_Repeat_13"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1050.22 404.9a2.41 2.41 0 00-3 1.62L1036.64 442a2.43 2.43 0 001.62 3 2.41 2.41 0 003-1.63l10.58-35.42a2.42 2.42 0 00-1.62-3.05z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1044.7 428.14l-.06-.11a2.12 2.12 0 00-2.88-.86l-11.15 6a2.12 2.12 0 00-.86 2.88l.06.11a2.12 2.12 0 002.87.87l11.16-6a2.14 2.14 0 00.86-2.89z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1042.49 428l-.23.12a2.12 2.12 0 00-.86 2.87l5.57 10.34a2.11 2.11 0 002.86.86l.23-.13a2.12 2.12 0 00.86-2.87l-5.57-10.33a2.1 2.1 0 00-2.86-.86z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_14"
                data-name="&lt;Radial Repeat&gt;"
              >
                <rect
                  className="water_slide_1__cls-3"
                  x={1016.17}
                  y={414.86}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1036.696 417.204)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1034.48 419.28l.07-.11a2.11 2.11 0 00-.69-2.92l-10.79-6.67a2.12 2.12 0 00-2.92.7l-.07.11a2.11 2.11 0 00.69 2.92l10.79 6.67a2.12 2.12 0 002.92-.7z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1033.5 417.29l-.22-.13a2.11 2.11 0 00-2.91.69l-6.15 10a2.12 2.12 0 00.69 2.91l.22.14a2.12 2.12 0 002.91-.69l6.15-10a2.12 2.12 0 00-.69-2.92z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_15"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1054.36 422.4a2.42 2.42 0 00.1-3.42l-25.33-26.88a2.41 2.41 0 00-3.41-.1 2.42 2.42 0 00-.1 3.41L1051 422.3a2.4 2.4 0 003.36.1z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1028.86}
                  y={395.33}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1037.305 397.532)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1028.05}
                  y={395.81}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1030.337 403.767)"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_16"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1044.28 424.8a2.41 2.41 0 003-1.63l10.58-35.42a2.42 2.42 0 00-1.62-3 2.41 2.41 0 00-3 1.62l-10.58 35.43a2.43 2.43 0 001.62 3z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1049.8 401.55l.06.12a2.13 2.13 0 002.88.86l11.15-6a2.12 2.12 0 00.86-2.88l-.06-.12a2.11 2.11 0 00-2.87-.86l-11.16 6a2.13 2.13 0 00-.86 2.88z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1052 401.7l.23-.13a2.11 2.11 0 00.86-2.86l-5.57-10.34a2.11 2.11 0 00-2.86-.86l-.23.13a2.11 2.11 0 00-.86 2.86l5.57 10.34a2.11 2.11 0 002.86.86z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_17"
                data-name="&lt;Radial Repeat&gt;"
              >
                <rect
                  className="water_slide_1__cls-3"
                  x={1036.6}
                  y={410}
                  width={41.73}
                  height={4.83}
                  rx={2.41}
                  transform="rotate(-13.33 1057.128 412.349)"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1060 410.41l-.07.12a2.11 2.11 0 00.69 2.92l10.79 6.67a2.12 2.12 0 002.92-.7l.07-.11a2.13 2.13 0 00-.69-2.93l-10.79-6.66a2.11 2.11 0 00-2.92.69z"
                />
                <path
                  className="water_slide_1__cls-3"
                  d="M1061 412.4l.22.14a2.12 2.12 0 002.91-.69l6.15-10a2.12 2.12 0 00-.69-2.91l-.22-.14a2.12 2.12 0 00-2.91.69l-6.15 10a2.11 2.11 0 00.69 2.91z"
                />
              </g>
              <g
                id="water_slide_1___Radial_Repeat_18"
                data-name="&lt;Radial Repeat&gt;"
              >
                <path
                  className="water_slide_1__cls-3"
                  d="M1040.14 407.3a2.41 2.41 0 00-.1 3.41l25.33 26.89a2.41 2.41 0 003.41.1 2.42 2.42 0 00.1-3.41l-25.34-26.89a2.39 2.39 0 00-3.4-.1z"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1048.68}
                  y={429.99}
                  width={16.95}
                  height={4.38}
                  rx={2.12}
                  transform="rotate(-88.35 1057.121 432.192)"
                />
                <rect
                  className="water_slide_1__cls-3"
                  x={1061.96}
                  y={417.94}
                  width={4.5}
                  height={15.95}
                  rx={2.11}
                  transform="rotate(-88.35 1064.25 425.894)"
                />
              </g>
            </g>
          </g>
          <g id="water_slide_1__steam">
            <ellipse
              className="water_slide_1__cls-3"
              cx={348.82}
              cy={401.11}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={315.91}
              cy={456.38}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={345.37}
              cy={503.17}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={386.43}
              cy={476.17}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={435.33}
              cy={490.61}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={453.5}
              cy={439.74}
              rx={9.09}
              ry={9.11}
            />
            <ellipse
              className="water_slide_1__cls-3"
              cx={395.52}
              cy={434.4}
              rx={9.09}
              ry={9.11}
            />
          </g>
          <g id="water_slide_1__rain">
            <path
              id="water_slide_1__vandr\xE5be"
              className="water_slide_1__cls-3"
              d="M687.23 383.66a.88.88 0 011.28.38c1.4 3.06 5.61 13.37 3.29 22.34-2.45 9.42-9.06 9.35-13.11 8.14s-9.41-5.81-6.47-14c3.12-8.73 12.19-15.06 15.01-16.86z"
            />
            <path
              id="water_slide_1__vandr\xE5be-2"
              data-name="vandr\xE5be"
              className="water_slide_1__cls-3"
              d="M687.23 449.84a.88.88 0 011.28.38c1.4 3.06 5.61 13.38 3.29 22.34-2.45 9.43-9.06 9.36-13.11 8.14s-9.41-5.8-6.47-14c3.12-8.7 12.19-15.06 15.01-16.86z"
            />
            <path
              id="water_slide_1__vandr\xE5be-3"
              data-name="vandr\xE5be"
              className="water_slide_1__cls-3"
              d="M727.75 415a.88.88 0 011.28.38c1.4 3.06 5.61 13.37 3.29 22.34-2.45 9.42-9.06 9.35-13.11 8.14s-9.41-5.81-6.47-14c3.12-8.74 12.19-15.07 15.01-16.86z"
            />
            <path
              id="water_slide_1__vandr\xE5be-4"
              data-name="vandr\xE5be"
              className="water_slide_1__cls-3"
              d="M748.86 478.42a.88.88 0 011.28.38c1.4 3.06 5.61 13.37 3.29 22.34-2.45 9.43-9.06 9.36-13.11 8.14s-9.41-5.8-6.47-14c3.15-8.73 12.15-15.06 15.01-16.86z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterNoAnimationSlide1;
