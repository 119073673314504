import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const LastSlideImage = () => (
  <StaticImage
    style={{
      width: '140%%',
      height: '100%',
      objectFit: 'cover',
      marginTop: '-140px',
      marginLeft: '-180px',
    }}
    layout="fullWidth"
    alt=""
    placeholder="blurred"
    src="../../images/water_last_slide_image.png"
    formats={['auto']}
  />
);

export default LastSlideImage;
