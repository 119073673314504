import * as React from 'react';

function WaterNoAnimationSlide3(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 797" {...props}>
      <defs>
        <filter id="water-shadow-slide-3" colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="10" floodOpacity="0.5" />
        </filter>
        <clipPath id="water_slide_3__clip-path">
          <path className="water_slide_3__cls-1" d="M0 0h1440v797H0z" />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-2">
          <path
            className="water_slide_3__cls-2"
            d="M299 434a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.27 3.27 0 010-4.57 39.71 39.71 0 0156.84 0z"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-3">
          <path
            className="water_slide_3__cls-1"
            d="M279.74 433.73a4.53 4.53 0 105.45-3.36 4.54 4.54 0 00-5.45 3.36"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-4">
          <path
            className="water_slide_3__cls-2"
            d="M560.7 573.65a3.28 3.28 0 010 4.58 39.72 39.72 0 01-56.84 0 3.3 3.3 0 010-4.58 39.72 39.72 0 0156.84 0z"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-5">
          <path
            className="water_slide_3__cls-1"
            d="M541.42 575.31A4.53 4.53 0 10545 570a4.53 4.53 0 00-3.61 5.29"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-6">
          <path
            className="water_slide_3__cls-2"
            d="M1210.78 596.32a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.29 3.29 0 010-4.57 39.71 39.71 0 0156.84 0z"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-7">
          <path
            className="water_slide_3__cls-1"
            d="M1191.5 598a4.53 4.53 0 103.61-5.29 4.53 4.53 0 00-3.61 5.29"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-8">
          <path
            className="water_slide_3__cls-1"
            d="M731.08 543.38a8.11 8.11 0 109.76-6 8.11 8.11 0 00-9.76 6"
          />
        </clipPath>
        <clipPath id="water_slide_3__clip-path-9">
          <path
            className="water_slide_3__cls-1"
            d="M723.55 531.51l-1 12.79a4 4 0 00.43 2.1l2.85 5.59a4 4 0 003.24 2.16l11.61.86a4 4 0 003.76-2l2.36-4.25a4 4 0 011.93-1.72l6.54-2.77 1.11-14.27-28.58-2.13a4 4 0 00-4.25 3.64z"
          />
        </clipPath>
        <filter
          id="water_slide_3__luminosity-invert"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feColorMatrix values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0" />
        </filter>
        <style>
          {
            '.water_slide_3__cls-1,.water_slide_3__cls-20,.water_slide_3__cls-7{fill:none}.water_slide_3__cls-2{fill:#6ca1ff}.water_slide_3__cls-7{stroke:#000;stroke-width:1.74px}.water_slide_3__cls-16,.water_slide_3__cls-7{stroke-linecap:round;stroke-linejoin:round}.water_slide_3__cls-9{fill:#f2f3f3}.water_slide_3__cls-10{fill:#4a494b}.water_slide_3__cls-16,.water_slide_3__cls-17{fill:#e8d774}.water_slide_3__cls-16,.water_slide_3__cls-20{stroke:#e8d774}.water_slide_3__cls-16{stroke-width:9.05px}.water_slide_3__cls-20{stroke-width:8.91px;stroke-miterlimit:10}.water_slide_3__cls-30{fill:#d7be4b}.water_slide_3__cls-34{fill:#80dd92}.water_slide_3__cls-36{fill:#40ab83}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g
          clipPath="url(#water_slide_3__clip-path)"
          id="water_slide_3__Layer_1"
          data-name="Layer 1"
        >
          <g id="water_slide_3__float">
            <g
              id="water_slide_3__fishes"
            >
              <path
                className="water_slide_3__cls-2"
                d="M244.74 434.56l-14.3-13.37a1.94 1.94 0 00-2.36-.19 1.68 1.68 0 00-.69 2l4.75 13.26-4.75 13.25a1.68 1.68 0 00.69 2 1.93 1.93 0 001.06.31 1.89 1.89 0 001.3-.5l14.3-13.37a3.14 3.14 0 000-3.37zM275.47 423.93s-6.85-6.51-10.25-6.51-2.17 6.51-2.17 6.51z"
              />
              <path
                className="water_slide_3__cls-2"
                d="M299 434a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.27 3.27 0 010-4.57 39.71 39.71 0 0156.84 0z"
              />
              <g clipPath="url(#water_slide_3__clip-path-2)">
                <path
                  className="water_slide_3__cls-2"
                  d="M299 434a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.27 3.27 0 010-4.57 39.71 39.71 0 0156.84 0z"
                />
                <path
                  className="water_slide_3__cls-7"
                  d="M294 445.67s-8.49 1.83-12.15-1.06"
                />
              </g>
              <path
                className="water_slide_3__cls-2"
                d="M275.38 448.13l-12 11.42c-.32.31-1.14-.08-1.07-.51l2.11-12.54c0-.18.23-.28.48-.25l9.93 1.11c.45.06.81.52.55.77z"
              />
              <g clipPath="url(#water_slide_3__clip-path-3)">
                <path
                  className="water_slide_3__cls-9"
                  d="M279.74 433.73a4.53 4.53 0 105.45-3.36 4.54 4.54 0 00-5.45 3.36"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M284.08 434a4.53 4.53 0 105.45-3.36 4.51 4.51 0 00-5.45 3.36"
                />
              </g>
            </g>
            <g
              id="water_slide_3__fishes"
            >
              <path
                className="water_slide_3__cls-2"
                d="M244.74 434.56l-14.3-13.37a1.94 1.94 0 00-2.36-.19 1.68 1.68 0 00-.69 2l4.75 13.26-4.75 13.25a1.68 1.68 0 00.69 2 1.93 1.93 0 001.06.31 1.89 1.89 0 001.3-.5l14.3-13.37a3.14 3.14 0 000-3.37zM275.47 423.93s-6.85-6.51-10.25-6.51-2.17 6.51-2.17 6.51z"
              />
              <path
                className="water_slide_3__cls-2"
                d="M299 434a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.27 3.27 0 010-4.57 39.71 39.71 0 0156.84 0z"
              />
              <g clipPath="url(#water_slide_3__clip-path-2)">
                <path
                  className="water_slide_3__cls-2"
                  d="M299 434a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.27 3.27 0 010-4.57 39.71 39.71 0 0156.84 0z"
                />
                <path
                  className="water_slide_3__cls-7"
                  d="M294 445.67s-8.49 1.83-12.15-1.06"
                />
              </g>
              <path
                className="water_slide_3__cls-2"
                d="M275.38 448.13l-12 11.42c-.32.31-1.14-.08-1.07-.51l2.11-12.54c0-.18.23-.28.48-.25l9.93 1.11c.45.06.81.52.55.77z"
              />
              <g clipPath="url(#water_slide_3__clip-path-3)">
                <path
                  className="water_slide_3__cls-9"
                  d="M279.74 433.73a4.53 4.53 0 105.45-3.36 4.54 4.54 0 00-5.45 3.36"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M284.08 434a4.53 4.53 0 105.45-3.36 4.51 4.51 0 00-5.45 3.36"
                />
              </g>
            </g>
            <g
              id="water_slide_3__fishes-2"
              data-name="fishes"
            >
              <path
                className="water_slide_3__cls-2"
                d="M506.47 574.26l-14.3-13.37a1.93 1.93 0 00-2.37-.19 1.68 1.68 0 00-.69 2l4.75 13.25-4.75 13.25a1.68 1.68 0 00.69 2 1.89 1.89 0 001.07.32 1.93 1.93 0 001.3-.5l14.3-13.38a3.18 3.18 0 000-3.36zM537.19 563.63s-6.85-6.51-10.25-6.51-2.17 6.51-2.17 6.51z"
              />
              <path
                className="water_slide_3__cls-2"
                d="M560.7 573.65a3.28 3.28 0 010 4.58 39.72 39.72 0 01-56.84 0 3.3 3.3 0 010-4.58 39.72 39.72 0 0156.84 0z"
              />
              <g clipPath="url(#water_slide_3__clip-path-4)">
                <path
                  className="water_slide_3__cls-2"
                  d="M560.7 573.65a3.28 3.28 0 010 4.58 39.72 39.72 0 01-56.84 0 3.3 3.3 0 010-4.58 39.72 39.72 0 0156.84 0z"
                />
                <path
                  className="water_slide_3__cls-7"
                  d="M555.68 585.37s-8.48 1.83-12.15-1.06"
                />
              </g>
              <path
                className="water_slide_3__cls-2"
                d="M537.11 587.82l-12 11.43c-.33.3-1.14-.09-1.07-.51l2.11-12.54c0-.18.22-.28.47-.25l9.94 1.11c.44.05.81.52.55.76z"
              />
              <g clipPath="url(#water_slide_3__clip-path-5)">
                <path
                  className="water_slide_3__cls-9"
                  d="M541.42 575.31A4.53 4.53 0 10545 570a4.53 4.53 0 00-3.61 5.29"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M545.49 573.78a4.53 4.53 0 103.61-5.28 4.53 4.53 0 00-3.61 5.28"
                />
              </g>
            </g>
            <g
              id="water_slide_3__fishes-2"
              data-name="fishes"
            >
              <path
                className="water_slide_3__cls-2"
                d="M506.47 574.26l-14.3-13.37a1.93 1.93 0 00-2.37-.19 1.68 1.68 0 00-.69 2l4.75 13.25-4.75 13.25a1.68 1.68 0 00.69 2 1.89 1.89 0 001.07.32 1.93 1.93 0 001.3-.5l14.3-13.38a3.18 3.18 0 000-3.36zM537.19 563.63s-6.85-6.51-10.25-6.51-2.17 6.51-2.17 6.51z"
              />
              <path
                className="water_slide_3__cls-2"
                d="M560.7 573.65a3.28 3.28 0 010 4.58 39.72 39.72 0 01-56.84 0 3.3 3.3 0 010-4.58 39.72 39.72 0 0156.84 0z"
              />
              <g clipPath="url(#water_slide_3__clip-path-4)">
                <path
                  className="water_slide_3__cls-2"
                  d="M560.7 573.65a3.28 3.28 0 010 4.58 39.72 39.72 0 01-56.84 0 3.3 3.3 0 010-4.58 39.72 39.72 0 0156.84 0z"
                />
                <path
                  className="water_slide_3__cls-7"
                  d="M555.68 585.37s-8.48 1.83-12.15-1.06"
                />
              </g>
              <path
                className="water_slide_3__cls-2"
                d="M537.11 587.82l-12 11.43c-.33.3-1.14-.09-1.07-.51l2.11-12.54c0-.18.22-.28.47-.25l9.94 1.11c.44.05.81.52.55.76z"
              />
              <g clipPath="url(#water_slide_3__clip-path-5)">
                <path
                  className="water_slide_3__cls-9"
                  d="M541.42 575.31A4.53 4.53 0 10545 570a4.53 4.53 0 00-3.61 5.29"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M545.49 573.78a4.53 4.53 0 103.61-5.28 4.53 4.53 0 00-3.61 5.28"
                />
              </g>
            </g>
            <g
              id="water_slide_3__fishes-3"
              data-name="fishes"
            >
              <path
                className="water_slide_3__cls-2"
                d="M1156.54 596.92l-14.3-13.37a1.94 1.94 0 00-2.36-.19 1.68 1.68 0 00-.69 2l4.75 13.25-4.75 13.26a1.68 1.68 0 00.69 2 1.93 1.93 0 001.06.31 1.89 1.89 0 001.3-.5l14.3-13.37a3.14 3.14 0 000-3.37zM1187.27 586.29s-6.85-6.51-10.25-6.51-2.17 6.51-2.17 6.51z"
              />
              <path
                className="water_slide_3__cls-2"
                d="M1210.78 596.32a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.29 3.29 0 010-4.57 39.71 39.71 0 0156.84 0z"
              />
              <g clipPath="url(#water_slide_3__clip-path-6)">
                <path
                  className="water_slide_3__cls-2"
                  d="M1210.78 596.32a3.29 3.29 0 010 4.57 39.71 39.71 0 01-56.84 0 3.29 3.29 0 010-4.57 39.71 39.71 0 0156.84 0z"
                />
                <path
                  className="water_slide_3__cls-7"
                  d="M1205.76 608s-8.49 1.83-12.15-1.06"
                />
              </g>
              <path
                className="water_slide_3__cls-2"
                d="M1187.18 610.49l-12 11.42c-.32.31-1.14-.08-1.07-.51l2.11-12.54c0-.18.23-.28.48-.25l9.93 1.11c.45.06.81.52.55.77z"
              />
              <g clipPath="url(#water_slide_3__clip-path-7)">
                <path
                  className="water_slide_3__cls-9"
                  d="M1191.5 598a4.53 4.53 0 103.61-5.29 4.53 4.53 0 00-3.61 5.29"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M1195.56 596.45a4.53 4.53 0 103.62-5.29 4.53 4.53 0 00-3.62 5.29"
                />
              </g>
            </g>
            <g
              id="water_slide_3__bike_and_bird_grp"
              data-name="bike and bird grp"
            >
              <path
                className="water_slide_3__cls-16"
                d="M687.06 588.81l5.93 45.67 64.5-42.97-70.43-2.7z"
              />
              <rect
                className="water_slide_3__cls-17"
                x={660.6}
                y={599.79}
                width={19.21}
                height={61.63}
                rx={9.61}
                transform="rotate(-85.73 670.191 630.601)"
              />
              <rect
                x={746.82}
                y={577.31}
                width={17.24}
                height={16.41}
                rx={8.21}
                transform="rotate(-85.73 755.432 585.51)"
                strokeWidth={8.91}
                stroke="#e8d774"
                fill="#e8d774"
                strokeLinejoin="round"
                strokeLinecap="round"
              />
              <path
                className="water_slide_3__cls-16"
                d="M695.42 594.13l-10.72-.8a9.41 9.41 0 01-8.69-10.1l20.11 1.5z"
              />
              <path
                strokeMiterlimit={10}
                strokeWidth={8.91}
                stroke="#e8d774"
                strokeLinecap="round"
                fill="none"
                d="M763.98 639.06l-12.35-60.26"
              />
              <g
                className="wheel"
              >
                <circle
                  className="water_slide_3__cls-20"
                  cx={649.22}
                  cy={629.04}
                  r={25.85}
                  id="water_slide_3__wheel"
                />
                <g id="Inside-wheel">
                  <line className="cls-25" x1="634.28" y1="648.03" x2="664.17" y2="613.17" stroke="#e8d774" strokeWidth="6px" />
                  <line className="cls-25" x1="631.79" y1="615.66" x2="666.66" y2="645.54" stroke="#e8d774" strokeWidth="6px" />
                  <line className="cls-25" x1="626.33" y1="632.36" x2="672.12" y2="628.84" stroke="#e8d774" strokeWidth="6px" />
                  <line className="cls-25" x1="647.46" y1="607.71" x2="650.99" y2="653.49" stroke="#e8d774" strokeWidth="6px" />
                </g>
              </g>
              <g
                className="wheel"
              >
                <circle
                  className="water_slide_3__cls-20"
                  cx={764.35}
                  cy={637.63}
                  r={25.85}
                  id="water_slide_3__wheel-2"
                  data-name="wheel"
                />
                <g>
                  <line x1="749.04" y1="656.5" x2="778.92" y2="621.63" stroke="#e8d774" strokeWidth="6px" />
                  <line x1="746.55" y1="624.12" x2="781.41" y2="654.01" stroke="#e8d774" strokeWidth="6px" />
                  <line x1="741.09" y1="640.83" x2="786.87" y2="637.31" stroke="#e8d774" strokeWidth="6px" />
                  <line x1="762.22" y1="616.17" x2="765.74" y2="661.96" stroke="#e8d774" strokeWidth="6px" />
                </g>
              </g>
              <path
                d="M699.45 605.31c1.42-.34 2.21-2.12 1.77-4l-5.8-24.46c-.44-1.87-1.95-3.11-3.37-2.77-1.42.33-2.21 2.12-1.77 4l5.8 24.47c.44 1.86 1.92 3.1 3.37 2.76z"
                fill="#97475b"
              />
              <path
                d="M767.94 550.45l-14.88-6.29a3.24 3.24 0 00-4.23 1.72l-.91 2.15a3.23 3.23 0 001.72 4.23l14.66 6.2a4.53 4.53 0 005.92-2.41 4.27 4.27 0 00-2.28-5.6"
                fill="#faee79"
              />
              <path
                d="M752.8 544.05l-3.39 8 10.31 4.36c-5.69-2.4-8.79-7.95-6.92-12.38"
                fill="#f5d169"
              />
              <path
                d="M752.17 537.22l-10.26-4.34a62.68 62.68 0 00-82.14 33.35l-3.16 7.47a1.22 1.22 0 00.65 1.61l49.75 21a21.85 21.85 0 0028.61-11.61l18.27-43.25a3.24 3.24 0 00-1.72-4.25"
                fill="#e17a47"
              />
              <path
                d="M742.12 566.18l-3-1.26a19 19 0 00-24.75 10.18l-8.25 19.52a1.25 1.25 0 00.65 1.62l1 .42a21.11 21.11 0 0027.58-11.34l7.41-17.52a1.25 1.25 0 00-.65-1.62"
                fill="#ec9b5f"
              />
              <g clipPath="url(#water_slide_3__clip-path-8)">
                <path
                  className="water_slide_3__cls-9"
                  d="M731.08 543.38a8.11 8.11 0 109.76-6 8.11 8.11 0 00-9.76 6"
                />
                <path
                  className="water_slide_3__cls-10"
                  d="M738.84 543.84a8.11 8.11 0 109.76-6 8.11 8.11 0 00-9.76 6"
                />
              </g>
              <path
                d="M695.75 589.7l14.25 5.59c19.93 7.82 41 1.21 49.3-14.27l.54-1a1.56 1.56 0 00-.71-2.11l-33.29-17.4C719 557 710.93 558.8 708 564.72z"
                fill="#d16b3e"
              />
              <g>
                <g clipPath="url(#water_slide_3__clip-path-9)">
                  <path
                    className="water_slide_3__cls-17"
                    d="M723.55 531.51l-1 12.79a4 4 0 00.43 2.1l2.85 5.59a4 4 0 003.24 2.16l11.61.86a4 4 0 003.76-2l2.36-4.25a4 4 0 011.93-1.72l6.54-2.77 1.11-14.27-28.58-2.13a4 4 0 00-4.25 3.64z"
                  />
                  <path
                    d="M727.51 533.79l-.69 9.12a3.06 3.06 0 00.38 1.7l2.52 4.55a3.54 3.54 0 002.84 1.76l7.28.54a3.48 3.48 0 003.28-1.62l2-3.43a3.34 3.34 0 011.67-1.4l8.65-3 .69-9.25-25-1.86a3.33 3.33 0 00-3.62 2.89z"
                    fill="#fff"
                  />
                  <rect
                    x={735.88}
                    y={536.1}
                    width={11.59}
                    height={8.78}
                    rx={4.39}
                    transform="rotate(-85.73 741.662 540.49)"
                  />
                </g>
                <path
                  className="water_slide_3__cls-30"
                  d="M697.33 528.19l27.39 2.04-.53 7.08-33.21-3.77 6.35-5.35z"
                />
                <path
                  className="water_slide_3__cls-17"
                  transform="rotate(-85.73 718.799 519.712)"
                  d="M698.12 517.57h41.38v4.29h-41.38z"
                />
                <path
                  className="water_slide_3__cls-30"
                  d="M735.91 558.5l4.82.36a2 2 0 011.87 2.14 2 2 0 01-2.17 1.86l-4.82-.36c-14.53-1.08-21.1-11.93-20.48-22.35l4.21.32c-.46 8.43 4.86 17.16 16.57 18.03z"
                />
              </g>
              <g
                id="water_slide_3__luft_bobler"
                data-name="luft bobler"
              >
                <circle cx={715.17} cy={485.62} r={6.57} fill="#557efd" />
                <g mask="url(#water_slide_3__mask)">
                  <circle cx={715.17} cy={485.62} r={6.57} opacity={0.35} />
                </g>
              </g>
              <g
                id="water_slide_3__luft_bobler"
                data-name="luft bobler"
              >
                <circle cx={715.17} cy={485.62} r={6.57} fill="#557efd" />
                <g mask="url(#water_slide_3__mask)">
                  <circle cx={715.17} cy={485.62} r={6.57} opacity={0.35} />
                </g>
              </g>
              <g
                id="water_slide_3__luft_bobler"
                data-name="luft bobler"
              >
                <circle cx={715.17} cy={485.62} r={6.57} fill="#557efd" />
                <g mask="url(#water_slide_3__mask)">
                  <circle cx={715.17} cy={485.62} r={6.57} opacity={0.35} />
                </g>
              </g>
            </g>
            <g id="water_slide_3__green-frame">
              <g
                filter="url(#water-shadow-slide-3)"
              >
                <path
                  id="water_slide_3__ground"
                  className="water_slide_3__cls-34"
                  d="M-67.09 522.68s389.18 105.54 579 122.66 577.61 27.9 780.05 28.46 174 89.9 174 89.9L-75.21 774.08z"
                />
                <path
                  className="water_slide_3__cls-34"
                  d="M876.92 640.53a3.22 3.22 0 013.66 1.26 1.14 1.14 0 01.1.19l6.6 17.1 11.44-30.33a3.12 3.12 0 013.15-1.85 3 3 0 012.86 2.22.55.55 0 010 .17l2.17 30.19 12.53-14.3a1 1 0 01.28-.23 3.33 3.33 0 013.47.1 2.77 2.77 0 011.27 3l-6 26.25a1.09 1.09 0 01-1.09.82h-39a1.08 1.08 0 01-1.11-1l-2.55-30.63a2.87 2.87 0 012.22-2.96zM373.24 604.65a3.24 3.24 0 013.67 1.26.66.66 0 01.09.19l6.61 17.1 11.44-30.33a3.12 3.12 0 013.15-1.87 3 3 0 012.86 2.22v.17l2.17 30.2 12.52-14.31a1.09 1.09 0 01.29-.23 3.33 3.33 0 013.47.1 2.79 2.79 0 011.27 3l-5.95 26.25a1.09 1.09 0 01-1.09.82h-39a1.07 1.07 0 01-1.11-1l-2.56-30.63a2.88 2.88 0 012.17-2.94z"
                />
              </g>
              <g>
                <path
                  filter="url(#water-shadow-slide-3)"
                  id="water_slide_3__ground-2"
                  data-name="ground"
                  d="M-88.46 728.72s383.38-73.2 573.18-56.08 575.71 66.19 778.16 66.75S1438.76 791 1438.76 791l-1541.14 10.38z"
                  fill="#6cc997"
                />
              </g>
              <g
                filter="url(#water-shadow-slide-3)"
              >
                <path
                  id="water_slide_3__FG"
                  className="water_slide_3__cls-36"
                  d="M-6.72 610s156.89-61.56 282.16-.16S423 721.57 570 744.81s-51.5 81.45-51.5 81.45h-543z"
                />
                <path
                  className="water_slide_3__cls-36"
                  d="M642.77 748.76c-38.74 3.89-200-24.16-249.34-99.39S211.9 479.09 103.38 471.1s-48.74 66-48.74 66 157.11-1.1 201.19 35.78S525.32 836 525.32 836h117.45z"
                />
              </g>
            </g>
            <g
              id="water_slide_3__frame"
              filter="url(#water-shadow-slide-3)"
            >
              <path
                d="M1275.45 32.86c-80.8-14.24-396.7 80.73-483.38 64.9S362.83-14.76 239.29 13.15C55.71 54.62-48.37 479.25 29.51 615.39 73.31 692 368.33 787.24 686.28 792.68c247.3 4.23 539.23-74.4 539.23-74.4 117.55-55.4 205.7-213.47 207.16-342.6S1356.28 47.1 1275.45 32.86zm44.61 496.25c-22.81 77.81-132.94 126.27-229.81 154.44-8.76 2.54-15.73-8-10.68-16.1 16.81-27 54.24-73.9 128.3-113.79 46.13-24.86 79.28-39.66 102.58-52.56 7.58-4.19 16.2 3.3 14 12.16q-2.12 8.12-4.39 15.85zm-132.76-19.69c-70.09 38.91-187.6 142.59-238 188.31a112.69 112.69 0 01-62.58 28.79c-165.28 19.4-666.72 7.29-752.18-101.81-97.15-124-30.16-454.22 81.89-549.94s398.28 30.16 509.34 60.61 382.28-83.74 505.46-44.6c72.61 23.08 106.39 133.37 111.06 227.21s-88.97 154.79-154.99 191.43z"
                fill="#6f91f4"
              />
              <path
                filter="url(#water-shadow-slide-3)"
                d="M-32.38-24.28v841.66h1496.85V-24.28zM114.3 647c-99-125.22-37.59-499.45 76.58-596.08S589.26 74 708.6 110.35c112.17 34.16 414.24-88.17 539.74-48.65 74 23.3 101.84 149.29 107.79 256.46 3.86 99.26-90.65 156.29-157.92 193.27-69.79 38.39-178.4 145.32-231.61 193A135.55 135.55 0 01892.73 738c-176.14 21.66-692.38 17.8-778.43-91zm1221.09-105.7c-23.76 80.32-202.13 179.5-286.93 168.11a11.32 11.32 0 01-8.84-15.67c10.17-24 57.72-104.68 142.74-150.06 48.23-25.74 106.06-47.39 128.74-59.09 7.94-4.1 20.41 1.84 24.59 9.75 4.65 8.79 1.96 39.33-.3 46.96z"
                fill="#6b79f2"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default WaterNoAnimationSlide3;
